import moment from 'moment';

const filters = {
  capitalize(value) {
    if (!value) { return ''; }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  dateFormat(value, format) {
    if (!value) { return ''; }
    format = format || 'YYYY-MM-DD';
    return moment(value).format(format);
  },
};

export default filters;
