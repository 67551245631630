import { farmService as service } from '@/service';
import _ from 'lodash';

export const state = {
  list: [],
  companyRegions: [],
  farmTurbines: [],
  farmStatusList: [],
  turbineStatusList: [],
  dataTypes: [{
    id: 1,
    name: 'ALL',
    display: '所有类型数据',
  }, {
    id: 2,
    name: 'fault',
    display: '一分钟故障数据',
  }, {
    id: 3,
    name: 'history',
    display: '五分钟数据',
  }, {
    id: 4,
    name: 'sec',
    display: '秒级数据',
  }, {
    id: 5,
    name: 'event',
    display: '事件数据',
  }, {
    id: 6,
    name: 'qd',
    display: '气动数据',
  }, {
    id: 7,
    name: 'cms',
    display: 'cms数据',
  }, {
    id: 8,
    name: 'ems_log',
    display: 'ems数据',
  }, {
    id: 9,
    name: 'qd-gslb',
    display: '高速录波',
  }],
  tags: [{
    id: 1,
    name: 'ALL',
  },
  {
    id: 2,
    name: 'Tag1',
  }, {
    id: 3,
    name: 'Tag2',
  }],
};

export const mutations = {

  SET_FARM_LIST(state, list) {
    if (list) {
      state.list = list;
      state.companyRegions = _.uniq(list.map((farm) => farm.companyRegion));
      state.map = {};
      list.forEach((f) => {
        state.map[f.pinyinCode] = f;
      });
    }
  },

  SET_FARM_TURBINES(state, turbines) {
    state.farmTurbines = turbines;
  },

  SET_TURBINE_SATATUS(state, turbineStatusList) {
    state.turbineStatusList = turbineStatusList;
  },

  SET_DATA_TYPES(state, dataTypes) {
    state.dataTypes = dataTypes;
  },
};

export const actions = {

  // 获取风场列表
  fetchFarmList({ commit, dispatch, getters }) {
    return service.fetchFarmList()
      .then((data) => {
        commit('SET_FARM_LIST', data);
        return data;
      });
  },

  // 根据pingyinCode过滤出风场列表
  searchFarms({ commit, dispatch, getters }, { pinyinCodes }) {
    const filterResult = state.list.filter((ent) => pinyinCodes.indexOf(ent.pinyinCode) >= 0);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-promise-executor-return
      return resolve(filterResult);
    });
  },

  // 获取风场下的风机列表
  fetchFarmTurbines({ commit, dispatch, getters }, { pinyinCode, keyword }) {
    const farms = state.list.filter((f) => f.pinyinCode === pinyinCode);
    let turbines = farms.length > 0 ? farms[0].turbines : [];
    turbines = turbines.filter((ent) => ent.turbineAliasName.toLocaleLowerCase().match(keyword.toLocaleLowerCase())
      || ent.turbineName.toLocaleLowerCase().match(keyword.toLocaleLowerCase())
      || ent.turbineId.match(keyword));
    commit('SET_FARM_TURBINES', turbines);
  },

  // 风场下的风机数据探索
  searchTurbineStatusList({ commit, dispatch, getters }, {
    farmPinyinCode, currentDate, dataType, turbines,
  } = {}) {
    return service.searchTurbineStatusList(farmPinyinCode, currentDate, dataType, turbines)
      .then((data) => {
        commit('SET_TURBINE_SATATUS', data);
        return data;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
