/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable no-var */
import moment from 'moment';
import router from '@/router';

export default {
  name: 'ExplorerTableView',
  props: ['type', 'turbineStatusList', 'selectedDataType', 'isLoading', 'isCms', 'currentDate'],
  data() {
    return {
      hideEmptyTurbine: true,
      selectedDataCategory: 2,
    };
  },
  computed: {
    pinyinCode() {
      const pinyinCode = this.farmPinyin ? this.farmPinyin : this.$route.params.pinyinCode;
      return pinyinCode;
    },
    daysOfMonth() {
      if (this.currentDate) {
        const monthDate = new Date(this.currentDate);
        const days = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();
        return days;
      }
      return '';
    },
    // 该风场下的所有风机
    farmTurbineList() {
      const farms = this.$store.state.farm.list.filter((f) => f.pinyinCode === this.pinyinCode);
      if (farms.length > 0) {
        return farms[0].turbines;
      }
      return [];
    },
  },
  methods: {
    changeMonth(m) {
      let currentDate = '';
      if (m === -1) {
        currentDate = moment(this.currentDate).subtract(1, 'months').format('YYYY-MM');
      } else {
        currentDate = moment(this.currentDate).add(1, 'months').format('YYYY-MM');
      }
      this.onChangeMonthEvent(currentDate);
    },
    showFarmRow(farmStatusData) {
      if (!this.hideEmptyFarm) {
        return true;
      }
      return farmStatusData.dates.filter((day) => day !== null).length > 0;
    },
    onChangeMonthEvent(currentDate) {
      this.$emit('changeMonth', currentDate);
    },
    convertToCamelCase(str) {
      const reg = /-(.)/g;
      return str.replace(reg, (fullMatch, g1, index) => {
        if (index === 0) return g1;
        return g1.toUpperCase();
      });
    },
    // eslint-disable-next-line consistent-return
    computeTurbineStatus(ts) {
      if (!ts) {
        return 'empty';
      }
      if (this.selectedDataCategory === 1) {
        if (ts.faultCount > 0) {
          return 'error';
        }
        if (ts.cosFileCount > 0) {
          return 'health';
        }
        return 'empty';
      }
      let cosCount = 0;
      if (this.selectedDataType) {
        switch (this.selectedDataType.name) {
          case 'fault':
            cosCount = ts.faultFileCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'history':
            cosCount = ts.historyFileCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'sec':
            cosCount = ts.secFileCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'event':
            cosCount = ts.eventFileCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'cms':
            cosCount = ts.cmsFileCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'ems_log':
            cosCount = ts.emsFileCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'qd':
            cosCount = ts.qdFileCount;
            return cosCount === 0 ? 'empty' : cosCount >= 3 ? 'full' : 'warning';
          case 'qd-gslb':
            cosCount = ts.qdGslbCount;
            return cosCount === 0 ? 'empty' : 'full';
          case 'ALL':
            var turbineFileStatusArr = [];
            var faultFileStatus = ts.faultFileCount === 0 ? 'empty' : 'full';
            var historyFileStatus = ts.historyFileCount === 0 ? 'empty' : 'full';
            var secFileStatus = ts.secFileCount === 0 ? 'empty' : 'full';
            var eventFileStatus = ts.eventFileCount === 0 ? 'empty' : 'full';
            var cmsFileStatus = ts.cmsFileCount === 0 ? 'empty' : 'full';
            var emsFileStatus = ts.emsFileCount === 0 ? 'empty' : 'full';
            var qdFileStatus = ts.qdFileCount === 0 ? 'empty' : (ts.qdFileCount >= 3 ? 'full' : 'warning');
            var qdGslbFileStatus = ts.qdGslbCount === 0 ? 'empty' : 'full';
            turbineFileStatusArr = [faultFileStatus, historyFileStatus, secFileStatus, eventFileStatus, cmsFileStatus, emsFileStatus, qdFileStatus, qdGslbFileStatus];
            if (turbineFileStatusArr.every((item) => item === 'full')) return 'full';
            if (turbineFileStatusArr.every((item) => item === 'empty')) return 'empty';
            if (turbineFileStatusArr.includes('empty') || turbineFileStatusArr.includes('warning')) return 'warning';
        }
      }
    },
    showTurbineRow(turbineStatusData) {
      if (!this.hideEmptyTurbine) {
        return true;
      }
      return turbineStatusData.dates.filter((day) => day !== null).length > 0;
    },
    farmNameClass(farm) {
      const currentDate = moment().format('YYYY-MM-DD');
      const lastyearData = moment(currentDate).add(-1, 'year').format('YYYY-MM-DD');
      if (farm.gridConnectedDate && moment(farm.gridConnectedDate).isBetween(lastyearData, currentDate)) {
        return 'isWithinYear';
      }
      return '';
    },
    openAnalyseTool(turbine) {
      // const type = this.selectedDataType.name.slice(0, 1) + this.selectedDataType.name.slice(1);
      // const routeData = this.$router.resolve({
      //   name: 'analyze-tool',
      //   query: {
      //     time: turbine.statusDate,
      //     turbineId: turbine.turbineId,
      //     type: type?.toLowerCase(),
      //   },
      // });
      // window.open(`//${window.location.host}/micro-apps/tool${routeData.href}`, '_blank');
    },
  },
};
