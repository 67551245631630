import { modelService as service } from '@/service';
import _ from 'lodash';

export const state = {
  pageData: {
    data: [],
    page: 1,
    limit: 20,
    total: 0,
  },
  // 模型列表
  list: [],
  modelInfos: [],
  modelMap: {},
  modelTags: [],
};

export const mutations = {

  SEARCH_MODEL(state, pageData) {
    state.pageData = pageData;
  },
  SET_MODESL(state, list) {
    state.list = list;
  },
  SET_MODEL_LIST(state, modelInfos) {
    state.modelInfos = modelInfos;
    state.modelMap = {};
    modelInfos.forEach((m) => {
      state.modelMap[m.modelName] = m;
    });
  },
  SET_MODEL_TAGS(state, tags) {
    state.modelTags = tags;
  },

};

export const actions = {

  // search model
  searchModel({ commit, dispatch, getters }, postData = {}) {
    return service.searchModel(postData)
      .then((pageData) => {
        const data = [];
        let id = 101;
        for (let i = 0; i < pageData.limit; i += 1) {
          data.push({
            id,
            modelEngName: `1P_Vibration${id}`,
            modelName: `油路换热${id}`,
            createdBy: '李庆宝',
            createdTime: '2017-11-11T13:16:12.000Z',
            description: '基于PCH录播数据进行1P监测，杜绝因1P（风轮不平衡）导致机组的重大故障',
            note: '整机',
            orderNo: id,
            refreshRate: null,
            state: 'plan',
            result: '温控阀坏',
            timeComsumed: '2',
            tags: 'ALL',
            type: null,
          });
          // eslint-disable-next-line no-plusplus
          id++;
        }
        pageData.data = data;
        commit('SEARCH_MODEL', pageData);
        return data;
      });
  },

  // 获取所有模型
  fetchModels({ commit, dispatch, getters }) {
    return service.fetchModels().then((data) => {
      commit('SET_MODESL', data);
      return data;
    });
  },
  fetchModelInfos({ commit, dispatch, getters }) {
    return service.fetchModelInfo()
      .then((data) => {
        if (data) {
          commit('SET_MODEL_LIST', data);
          return data;
        }
        return [];
      });
  },
  fetchModelTags({ commit, dispatch, getters }) {
    return service.fetchModelTags().then((res) => {
      let stateTags = [];
      if (this.state.auth.profile.role !== 'admin' && this.state.auth.profile.role !== 'model_developer' && !this.state.auth.profile.permissions.includes('internalModel')) {
        stateTags = res.filter((item) => item !== '内测模型');
      } else {
        const lastTag = res.filter((item) => item === '内测模型');
        stateTags = res.filter((item) => item !== '内测模型').concat(lastTag);
      }
      commit('SET_MODEL_TAGS', stateTags);
      return stateTags;
    });
  },

};

export default {
  state,
  mutations,
  actions,
  // getters,
};
