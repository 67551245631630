export const state = {
  dataTypes: [
    {
      id: 1,
      name: 'ALL',
      value: 'ALL',
      label: '所有cms数据',
    }, {
      id: 2,
      name: 'driveChain',
      value: ['mainBearing', 'gearBox', 'alternator'],
      label: '传动链数据',
      children: [{
        id: 3,
        name: 'mainBearing',
        value: ['mainBearing'],
        label: '主轴承',
        isChild: true,
      }, {
        id: 4,
        name: 'gearBox',
        value: ['gearBox'],
        label: '齿轮箱',
        isChild: true,
      }, {
        id: 5,
        name: 'alternator',
        value: ['alternator'],
        label: '发电机',
        isChild: true,
      }],
    }, {
      id: 6,
      name: 'blade',
      label: '叶片数据',
      value: ['blade1', 'blade2', 'blade3'],
      children: [{
        id: 7,
        name: 'blade1',
        value: ['blade1'],
        label: '叶片一',
        isChild: true,
      }, {
        id: 8,
        name: 'blade2',
        value: ['blade2'],
        label: '叶片二',
        isChild: true,
      }, {
        id: 9,
        name: 'blade3',
        value: ['blade3'],
        label: '叶片三',
        isChild: true,
      }],
    }, {
      id: 10,
      name: 'tower',
      value: ['towerTop', 'towerBottom'],
      label: '塔筒数据',
      children: [{
        id: 11,
        name: 'towerTop',
        value: ['towerTop'],
        label: '塔顶',
        isChild: true,
      }, {
        id: 12,
        name: 'towerBottom',
        value: ['towerBottom'],
        label: '塔底',
        isChild: true,
      }],
    },
  ],
  toolHeaderOptions: {},
};
export const mutations = {
  SET_TOOL_HEADER_OPTIONS(state, options) {
    state.toolHeaderOptions = options;
  },
};
export const actions = {};
export default {
  state,
  mutations,
  actions,
};
