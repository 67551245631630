<script>
import appConfig from '@/app.config';
import Layout from '@/views/layouts/main.vue';
import moment from 'moment';
import { cmsService as service } from '@/service';
import _ from 'lodash';

export default {
  name: 'CmsModelSummary',
  page: {
    title: 'CMS模型预警',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
  },
  data() {
    return {
      orderModel: {
        field: 'farmName',
        sort: 'desc',
      },
      dateRangeStr: '',
      headerData: [],
      modelGroupsSecondRow: [],
      tableData: {},
      modelFarms: [],
    };
  },
  computed: {
    farmList() {
      return this.$store.state.farm.list;
    },
    farmsMap() {
      return this.$store.state.farm.map;
    },
  },

  watch: {

  },

  mounted() {
    this.getCmsModelFarms();
    this.getTableHeader();
    this.getTableData();
  },

  methods: {
    setOrder(field) {
      if (this.orderModel.field === field) {
        if (this.orderModel.sort === 'asc') {
          this.orderModel.sort = 'desc';
        } else {
          this.orderModel.sort = 'asc';
        }
      } else {
        this.orderModel.field = field;
        this.orderModel.sort = 'desc';
      }
      this.farmList.sort(this.orderList(field, this.orderModel.sort === 'asc'));
    },
    setOrderStyle(filed) {
      if (this.orderModel.field === filed) {
        if (this.orderModel.sort === 'asc') {
          return 'sorting_asc';
        }
        return 'sorting_desc';
      }
      return '';
    },
    orderList(attr, rev) {
      // 第二个参数没有传递 默认升序排列
      if (rev === undefined) {
        rev = 1;
      } else {
        rev = (rev) ? 1 : -1;
      }
      // eslint-disable-next-line func-names
      return function (a, b) {
        a = a[attr];
        b = b[attr];
        if (a < b) {
          return rev * -1;
        }
        if (a > b) {
          return rev * 1;
        }
        return 0;
      };
    },
    getTableHeader() {
      service.getModelHeader().then((res) => {
        this.headerData = res;
      });
    },
    getCmsModelFarms() {
      service.getCmsModelFarms().then((res) => {
        this.modelFarms = res.map((farm) => farm.pinyinCode);
      });
    },
    getTableData() {
      service.fetchCmsSummary().then((result) => {
        this.tableData = result;
      });
    },
    initDateRangeStr() {
      const dateFrom = moment().subtract(30, 'd').format('YYYY-MM-DD');
      const dateTo = moment().format('YYYY-MM-DD');
      this.dateRangeStr = `${dateFrom} 至 ${dateTo}`;
    },
    doSearch() {
      setTimeout(() => {
        const times = this.dateRangeStr.split(' 至 ');
        const option = {
          from: times[0],
          to: times[1],
        };
        service.getCmsModelSummary(option).then((result) => {
          this.tableData = result;
        });
      }, 10);
    },
    gotoFarmModelTable(pinyinCode) {
      this.$router.push({ name: 'cmsTurbineModelResult', params: { pinyinCode } });
    },
    display(pinyinCode, part) {
      const results = _.get(this.tableData, `${pinyinCode}.${part}`);
      if (!results) {
        return '';
      }
      const statuses = results.map((item) => item.status);
      const isFault = statuses.includes('故障');
      const isWarning = statuses.includes('告警');
      if (isFault) {
        return '<span class="error">故障</span>';
      }
      if (isWarning) {
        return '<span class="warning">告警</span>';
      }
      return '<span class="success">正常</span>';
    },
    gotoResults(pinyinCode, part) {
      // cmsFarmModelResults
      const routeData = this.$router.resolve({
        name: 'cmsFarmModelResults',
        params: {
          pinyinCode,
          part,
        },
      });
      window.open(routeData.href, '_blank');
    },

  },
};
</script>

<template>
  <Layout>
    <div class="cms-summary-wrapper">
      <div class="cms-summary">
        <div class="table-sticky-wrapper">
          <table class="table dataTable">
            <thead>
              <tr>
                <th>
                  <span>风场</span>
                </th>
                <th>
                  <span>风机数量</span>
                </th>
                <th v-for="part in headerData" :key="part.partAscription">
                  <span>{{ part.partAscription }}</span>
                  <div>{{ part.modelExecuteTimeLast|dateFormat('MM-DD') }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="Object.keys(tableData).length > 0">
              <tr v-for="pinyinCode in modelFarms" :key="pinyinCode">
                <td class="pointer" @click="gotoFarmModelTable(pinyinCode)">
                  {{ farmsMap[pinyinCode].farmName }}
                </td>
                <td>{{ farmsMap[pinyinCode].turbines.length }}</td>
                <td class="pointer" v-for="part in headerData" :key="`td_${part}`" @click="gotoResults(pinyinCode, part.partAscription)">
                  <span class="cursor-pointer" v-html="display(pinyinCode, part.partAscription)" />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr :colspan="3">
                <el-empty description="暂无数据" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
@import './model.scss' ;
$health: $success; // 数据完整
$warning: $warning; // 数据缺失
$error: #e7515a!important; // 数据报警
$empty: "#fafafa"; // 无数据
.empty {
  background: $empty;
}

.error {
  color:#9d2124!important;
}
.cms-summary-wrapper{
  padding: 10px;
}
.cms-summary{
  height: calc(100vh - 108px);
  overflow-y: auto;
  /* 滚动槽 */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .table-sticky-wrapper {
    .table{
      margin-top: 0 !important;
      thead tr:nth-child(1){
        th:nth-child(2){
          position: sticky;
          left: 119px;
          z-index: 3;
          span{
            width: 86px;
          }
        }
      }
    }
    tbody{
      tr{
        td:nth-of-type(2){
          position: sticky;
          left: 119px;
          z-index: 2;
          background: #f4f3fb;
        }
      }
    }
  }
}
</style>
