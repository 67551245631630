export const state = {
  currentTabName: 'overview',
};

export const mutations = {

  SET_TABNAME(state, currentTabName) {
    state.currentTabName = currentTabName;
  },

};

export const actions = {};

export default {
  state,
  mutations,
  actions,
  // getters,
};
