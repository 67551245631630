import { otherService as service } from '@/service';

export const state = {
  catelogs: [],
};

export const mutations = {

  SET_CATELOGS(state, catelogs) {
    state.catelogs = catelogs;
  },
  PUSH_CATELOGS(state, catelogs) {
    state.catelogs.push(catelogs);
  },
};

export const actions = {
  getCatelogs({ commit, dispatch, getters }) {
    return service.fetchCatelogs().then((data) => {
      commit('SET_CATELOGS', data || []);
      return data;
    });
  },

};

export default {
  state,
  mutations,
  actions,
};
