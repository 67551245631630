<script>
import appConfig from '@/app.config';
import ExplorerTableView from '@/views/explorer/turbine/explorer-table/index.vue';
import { uikit } from '@/utils';
import moment from 'moment';
import { cvtDataService as service, farmService } from '@/service';

export default {
  name: 'CvtTurbineExplorer',
  page: {
    title: '变流器数据下载-风机',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    ExplorerTableView,
  },
  data() {
    const currentDate = moment().toDate();
    const month = moment(currentDate).format('YYYY-MM');
    const { monthStart, monthEnd } = this.computeMonthDuration(month);
    return {
      isLoading: false,
      dateRangeStr: [monthStart, monthEnd],
      selectedTurbines: [],
      selectedTurbinesAllIschecked: false,
      turbineList: [],
      selectedDataType: {},
      progress: '',
      peekResult: {
        count: 0,
      },
      isFirst: false,
      checkedPeekResultTotal: 0,
      downloadStatus: null,
      downloadTypes: [],
      cosPeekTypes: [],
    };
  },
  computed: {
    pinyinCode() {
      return this.$route.params.pinyinCode;
    },
    currentMonthStr() {
      return moment(this.$route.params.currentDate).format('YYYY-MM');
    },
    dataTypes() {
      return this.$store.state.cvtData.dataTypes;
    },
    fileTypeList() {
      const arr = [];
      this.dataTypes.forEach((type) => {
        if (type.name !== 'ALL') {
          arr.push(type.name);
        }
        if (type.children) {
          type.children.forEach((subtype) => {
            arr.push(subtype.name);
          });
        }
      });
      return arr;
    },
    downloadPrecent() {
      if (this.downloadStatus) {
        const { progress, total } = this.downloadStatus;
        return Math.floor((progress / total) * 100);
      }
      return 0;
    },
    commonSearchOption() {
      let from = '';
      let to = '';
      if (this.dateRangeStr?.length) {
        const dateRanges = this.dateRangeStr;
        from = moment(dateRanges[0]).format('YYYY-MM-DD');
        to = moment(dateRanges[dateRanges.length - 1]).format('YYYY-MM-DD');
      }
      const option = {
        farms: [this.pinyinCode],
        currentDate: this.currentDate,
        turbines: this.selectedTurbines,
        from,
        to,
      };
      return option;
    },

  },
  watch: {
    commonSearchOption: {
      handler(newVal, oldValue) {
        this.getCheckedDownloadCount({ ...newVal, types: this.downloadTypes });
        this.getFileTypeCosfileCount({ ...newVal, types: this.cosPeekTypes });
      },
      deep: true,
    },
  },
  mounted() {
    this.selectedDataType = this.dataTypes[0];
    this.$refs.tree.setCurrentNode(this.selectedDataType);
    this.doSearch(this.currentMonthStr);
    this.getFileTypeCosfileCount(this.commonSearchOption);
  },
  methods: {
    computeMonthDuration(month) {
      const monthStart = moment(`${month}-01`, 'YYYY-MM-DD').format(
        'YYYY-MM-DD',
      );
      const monthEnd = moment(new Date()).format('YYYY-MM-DD');
      return {
        monthStart,
        monthEnd,
      };
    },
    getTurbineList(turbineList) {
      this.turbineList = turbineList;
    },
    doSearch(month) {
      this.isLoading = true;
      service.searchCvtTurbineCosStatus({ pinyinCode: this.pinyinCode, month: month || this.currentMonthStr }).then((res) => {
        this.turbineStatusList = res;
        this.isLoading = false;
      });
    },
    changeMonth(dateStr) {
      this.doSearch(dateStr);
    },
    handleNodeClick(data) {
      this.selectedDataType = data;
      const option = {
        ...this.commonSearchOption,
      };
      // 如果是子类型
      if (data.isChild) {
        option.types = [{ cvtBrand: data.parentNode, fileType: data.name }];
      } else if (data.name === 'ALL') {
        option.types = [];
      } else {
        option.types = data.children.map((item) => {
          return { cvtBrand: item.parentNode, fileType: item.name };
        });
      }
      this.cosPeekTypes = option.types;
      this.getFileTypeCosfileCount(option);
    },
    handleCheckChange(data) {
      const options = { ...this.commonSearchOption };
      options.types = [];
      if (data.name === 'ALL') {
        const ischecked = this.$refs.tree.getCheckedNodes().find((item) => item.name === 'ALL');
        if (ischecked) {
          options.types = [];
          this.getCheckedDownloadCount(options);
        } else {
          this.checkedPeekResultTotal = 0;
        }
        return;
      }
      const checkedNodes = this.$refs.tree.getCheckedNodes().filter((item) => item.isChild);
      if (!checkedNodes.length) {
        this.checkedPeekResultTotal = 0;
        return;
      }
      const tmp = checkedNodes.map((item) => {
        return {
          cvtBrand: item.parentNode,
          fileType: item.name,
        };
      });
      options.types = tmp;
      this.downloadTypes = options.types;
      this.getCheckedDownloadCount(options);
    },
    selectAllTurbines() {
      this.selectedTurbines = [];
      if (this.selectedTurbinesAllIschecked) {
        this.turbineList.forEach((item) => {
          this.selectedTurbines.push(item.turbineName);
        });
      } else {
        this.selectedTurbines = [];
      }
    },
    selectTurbinesChange(val) {
      if (val.length === this.turbineList.length) {
        this.selectedTurbinesAllIschecked = true;
      } else {
        this.selectedTurbinesAllIschecked = false;
      }
    },
    handleClickDonwloadBtn() {
      // 没有勾选任何类型或者勾选类型没有文件
      if (!this.checkedPeekResultTotal) {
        uikit.error('请先勾选要下载的文件！');
        return;
      }
      const options = {
        ...this.commonSearchOption,
        types: this.downloadTypes,
      };
      service.downloadCvt(options).then((result) => {
        const { count, jobId } = result;
        if (jobId) {
          this.progress = `正在下载 ${0 / count}`;
          this.checkDownloadJob(jobId);
        } else {
          uikit.error(result.message);
        }
      }).catch((err) => {
        uikit.error('系统报错，下载失败！');
        console.log('download cvt files', err);
      });
    },
    checkDownloadJob(jobId) {
      farmService.checkDownloadJob(jobId).then(({ progress, total, result }) => {
        this.downloadStatus = { progress, total, result };
        if (progress === total) {
          this.progress = `下载完成(${progress}/${total})，正在压缩打包...`;
        } else {
          this.progress = `正在下载 ${progress}/${total}`;
        }
        if (result) {
          this.progress = '';
          window.open(`/api/v3/temp/${result}`);
        } else {
          setTimeout(() => {
            this.checkDownloadJob(jobId);
          }, 5 * 1000);
        }
      });
    },
    getCheckedDownloadCount(options) {
      service.searchCvtCosPeek(options).then((result) => {
        this.checkedPeekResultTotal = result.count;
      });
    },
    getFileTypeCosfileCount(option) {
      service.searchCvtCosPeek(option).then((res) => {
        this.peekResult = res;
      });
    },
    donwloadHistory() {
      this.$router.push({
        name: 'userDownload',
        query: { username: this.$store.state.auth.profile.username },
      });
    },

  },

};
</script>

<template>
  <div class="col-12 explorer-container">
    <ExplorerTableView
      ref="explorerTable"
      :type="`cvt`"
      :current-date="currentMonthStr"
      :selected-data-type="selectedDataType"
      :turbine-status-list="turbineStatusList"
      :is-loading.sync="isLoading"
      :file-type-list="fileTypeList"
      :data-types="dataTypes"
      @changeMonth="changeMonth"
      @getTurbineList="getTurbineList"
    />
    <div class="widget-content widget-content-area filter-status-form">
      <div class="download-box">
        <div class="form-group">
          <div class="filter-list">
            <el-tree
              ref="tree"
              :data="dataTypes"
              show-checkbox
              default-expand-all
              :expand-on-click-node="false"
              node-key="id"
              highlight-current
              :props="{ children: 'children', label: 'label' }"
              @node-click="handleNodeClick"
              @check="handleCheckChange"
            />
          </div>
        </div>
        <div class="form-group">
          <el-select v-model="selectedTurbines" multiple filterable collapse-tags placeholder="风机 (多选)" class="sany-selector" @change="selectTurbinesChange">
            <el-checkbox v-model="selectedTurbinesAllIschecked" @change="selectAllTurbines">
              全选
            </el-checkbox>
            <el-option
              v-for="item in turbineList"
              :key="item.turbineId"
              :label="item.turbineName"
              :value="item.turbineName"
            />
          </el-select>
        </div>
        <div class="form-group">
          <el-date-picker
            v-model="dateRangeStr"
            :clearable="false"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </div>
        <div class="form-group">
          <el-button type="primary" size="medium" style="width:100%">
            当前文件总数 {{ peekResult && peekResult.count }} 个
          </el-button>
        </div>
        <div v-if="!progress" class="form-group">
          <el-button type="primary" size="medium" @click="handleClickDonwloadBtn" style="width:100%">
            <i class="fas fa-download" />
            勾选下载 {{ `(${checkedPeekResultTotal})` }}
          </el-button>
        </div>
        <div v-else class="browser-list">
          <div class="w-browser-details">
            <div class="w-browser-info">
              <p>{{ progress }}</p>
              <p class="browser-count">
                {{ downloadPrecent }}%
              </p>
            </div>
            <div class="w-browser-stats">
              <div class="progress br-30">
                <div
                  class="progress-bar bg-gradient-primary"
                  role="progressbar"
                  :style="{ width: downloadPrecent + '%' }"
                  :aria-valuenow="downloadPrecent"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <el-button plain class="btn-medium" type="primary" @click="donwloadHistory" size="medium" icon="el-icon-time">
            我的下载
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../explorer/explorer.scss";
  ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    color: #1b55e2;
    border-left: 2px solid #1b55e2;
    font-weight: 700;
  }
</style>
