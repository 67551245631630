import { Message } from 'element-ui';

const uikit = {
  success(text) {
    Message.success(text);
  },
  warning(text) {
    Message.warning(text);
  },
  error(text) {
    Message.error(text);
  },
  message(text) {
    Message.warning(text);
  },
};
export default uikit;
