<script>
import { AlignJustifyIcon, ImageIcon } from 'vue-feather-icons';
import ImgViewer from '@/components/ImgViewer';
import { cmsService as service } from '@/service';
import _ from 'lodash';

export default {
  components: {
    AlignJustifyIcon,
    ImageIcon,
    ImgViewer,
  },
  data() {
    return {
      headList: [{
        label: '表格',
        value: 'table',
        icon: 'AlignJustifyIcon',
      }, {
        label: '图片',
        value: 'image',
        icon: 'ImageIcon',
      }],
      curTab: 'table',
      history: [],
      combined: {},
      taskResultList: [],
      // modelExecuteTime: '2021-08-02',
      selectModelExecuteTime: '',
      imageTitleObj: { },
    };
  },
  computed: {
    farm() {
      const pinyinCode = this.$route.params.pinyinCode;
      const farm = this.$store.state.farm.map[pinyinCode];
      return farm || {};
    },
    partAscription() {
      return this.$route.params.part;
    },
  },
  mounted() {
    this.getTableDatas();
  },
  methods: {
    tabHandleClick(item) {
      this.curTab = item.value;
    },
    getTableDatas() {
      service.getCmsFarmModelResults(this.$route.params.pinyinCode, this.partAscription).then((res) => {
        this.history = res.modelExecuteTimes;
        this.selectModelExecuteTime = _.get(this.history[0], 'modelExecuteTime');
        this.combined = res.modelResults;
      });
    },
    getStatusStyle(result) {
      const status = _.get(result, 'status');
      if (status === '正常') {
        return 'normal';
      }
      if (status === '故障') {
        return 'danger';
      }
      if (status === '告警') {
        return 'warning';
      }
      return '';
    },
    changeSelectedTask(modelExecuteTime) {
      this.selectModelExecuteTime = modelExecuteTime;
    },
    show(turbineName, modelExecuteTime) {
      debugger;
      this.imageTitleObj = {
        farmName: this.farm.farmName,
        turbineName,
        displayName: this.partAscription,
        status: this.combined[`${turbineName}_${modelExecuteTime}`].status,
        description: this.combined[`${turbineName}_${modelExecuteTime}`].result,
      };
      this.$refs.viewer.show([{ src: this.combined[`${turbineName}_${modelExecuteTime}`].uploadFilePath }], 0);
    },
  },
};
</script>

<template>
  <div class="farm-model-result-container">
    <div class="farm-model-header">
      <div class="flex-row-start mr-2">
        <span v-for="item in headList" :key="item.value" class="cur-btn" :class="{ active: item.value === curTab }" @click="tabHandleClick(item)">
          <Component :is="item.icon" />
        </span>
      </div>
      <div class="farm-tag flex-row-start mr-10">
        <p>
          {{ farm.farmName }}
        </p>
      </div>
      <div class="flex-row-start  mr-10">
        <p style="color:#499ee8">
          {{ partAscription }}
        </p>
      </div>
    </div>
    <div class="result-content">
      <div v-show="curTab === 'table'" class="table-responsive">
        <div class="table-sticky-wrapper">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <span>数据范围</span>
                </th>
                <th v-for="item in history" :key="`th1_${item.modelExecuteTime}`">
                  <span>{{ item.dataStartTime }}-{{ item.dataEndTime }}</span>
                </th>
              </tr>
              <tr>
                <th rowspan="2">
                  <span>最新执行时间</span>
                </th>
                <th v-for="item in history" :key="`th2_${item.modelExecuteTime}`">
                  <span style="color:#5c83ea;font-size:12px;">{{ item.modelExecuteTime }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="farm.turbines.length > 0">
              <tr v-for="turbine in farm.turbines" :key="turbine.turbineName">
                <td><span>{{ turbine.turbineName }}</span></td>
                <td v-for="(item) in history" :key="`td_${item.modelExecuteTime}`">
                  <span
                    v-if="combined[`${turbine.turbineName}_${item.modelExecuteTime}`]"
                    :title="'查看结果图片'"
                    :class="getStatusStyle(combined[`${turbine.turbineName}_${item.modelExecuteTime}`])"
                    class="pointer"
                    @click="show(turbine.turbineName, item.modelExecuteTime)"
                  >
                    {{ combined[`${turbine.turbineName}_${item.modelExecuteTime}`].status }}
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <el-empty description="暂无数据" />
            </tbody>
          </table>
          <ImgViewer ref="viewer" :alt-obj="imageTitleObj" />
        </div>
      </div>
      <div v-show="curTab === 'image'" class="page-wrapper">
        <div class="page-content">
          <div v-if="farm.turbines.length > 0" class="grid-wrapper">
            <template v-for="turbine in farm.turbines">
              <div
                v-if="_.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}`)"
                :key="`img_${turbine.turbineName}`"
                class="grid-item"
                :class="{
                  isFault: _.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.status`) === '故障',
                  isWarning: _.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.status`) === '告警',
                }"
              >
                <div class="grid-header  flex-row-sp-between">
                  <span class="turbine-name">{{ turbine.turbineName }}</span>
                  <span class="comment-text">
                    <i v-if="_.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.status`) === '故障'" class="el-icon-error" />
                    <i v-else-if="_.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.status`) === '告警'" class="el-icon-warning" />
                    <i v-else class="el-icon-success" />
                    <span>{{ _.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.status`) }}</span>
                  </span>
                </div>
                <div class="pointer">
                  <img
                    :src="`/api/v3/cos/exchange?key=${encodeURIComponent(_.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.uploadFilePath`))}`"
                    alt=""
                    onerror="this.src='/static/images/default-img.png'"
                    @click="show(turbine.turbineName, selectModelExecuteTime)"
                  >
                </div>
                <div class="grid-bottom">
                  <span>{{ _.get(combined, `${turbine.turbineName}_${selectModelExecuteTime}.result`) }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="page-sidebar">
          <div class="table-responsive">
            <div class="dataTables_wrapper">
              <table v-loading="loading" class="dataTable table table-hover">
                <thead>
                  <tr>
                    <th />
                    <th>数据时间范围</th>
                    <th>执行时间</th>
                  </tr>
                </thead>
                <tbody v-if="history.length !== 0">
                  <tr v-for="item in history" :key="`history_${item.modelExecuteTime}`" style="cursor: pointer;" @click="changeSelectedTask(item.modelExecuteTime)">
                    <td class="checkbox-column">
                      <label class="new-control new-radio radio-info" style="height: 18px; margin: 0 auto;">
                        <input
                          :id="item.modelExecuteTime"
                          v-model="selectModelExecuteTime"
                          type="radio"
                          :value="item.modelExecuteTime"
                          class="new-control-input"
                          name="task-radio"
                        >
                        <span class="new-control-indicator" />
                      </label>
                    </td>
                    <td>{{ item.dataStartTime }} - {{ item.dataEndTime }}</td>
                    <td>{{ item.modelExecuteTime }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan=" 3">
                      <el-empty description="暂无数据" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './model.scss' ;
.farm-model-result-container{
  height: 100vh;
  .danger {
    background: #dc3545;
    color: #fff;
  }
  .warning {
    background: #ffc107;
    color: #fff;
  }
  .table > tbody tr {
    border-radius: 4px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    border-bottom: 1px solid #e0e6ed;
  }
}
.mr-10 {
  margin-right: 10px
}
.farm-model-header {
  height: 40px;
  line-height: 40px;
  padding: 0;
  display: flex;
  .cur-btn{
    display: inline-block;
    padding: 0 20px;
    cursor: pointer;
    &.active{
      background: #060818;
      color: #fff;
    }
  }
}
.result-content{
  height: calc(100% - 40px);
  .table-responsive{
    height: 100%;
    thead tr:nth-child(2) th:first-child{
      z-index: 3;
      border: 1px solid #e0e6ed;
      left: -1px;
    }
  }
}
.page-wrapper{
  height: 100%;
  display: flex;
  .page-content{
    flex: 1;
    overflow-y: auto;
    border-left: 1px solid #e0e6ed;
    background: #f1f2f3;
    justify-content: space-around;
    padding: 20px 0 30px 20px;
  }
  .grid-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill,220px);
    grid-gap: 15px;
    .grid-item{
      background: #fff;
      position: relative;
    }
    .grid-header{
      border-bottom: 1px solid #e0e6ed;
      padding: 4px 6px;
      color: #fff;
      background-color: #8dbf42;
      position: relative;
    }
    img{
      width: 200px;
      height: 200px;
    }
  }
  .page-sidebar{
    width: 460px;
    padding: 0 20px 20px;
  }
}
</style>
