<template>
  <LoginView />
</template>

<script>
import DefaultLoginView from './DefaultLoginView';
import LabLoginView from './LabLoginView';

const config = {
  components: {},
};

switch (process.env.VUE_APP_LOGIN) {
  case 'lab':
    config.components.LoginView = LabLoginView;
    break;
  default:
    config.components.LoginView = DefaultLoginView;
    break;
}

export default config;
</script>

<style lang="scss" scoped>
</style>
