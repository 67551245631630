import { authService as service } from '@/service';
import farm from './farm';

export const state = {
  token: null,
  profile: {
    id: '',
    level: '',
    nickname: '',
    role: '',
    username: '',
  },
};

export const mutations = {

  LOGIN_SUCCESS(state, token) {
    state.token = token;
  },

  SET_PROFILE(state, profile) {
    state.profile = profile;
  },

  CLEAR_TOKEN(STATE) {
    STATE.token = null;
  },

};

export const getters = {
  // Whether the user is currently logged in.
  isLoggedIn(state) {
    if (state.profile && state.profile.id) {
      return true;
    }
    return false;
  },
};

export const actions = {

  // Logs in
  login({ commit, dispatch, getters }, params = {}) {
    const {
      username, password, remember, verifyData,
    } = params;
    return service.loginWithRsa(username, password, remember, verifyData)
      .then((data) => {
        if (data.message === 'ok') {
          const token = data.token;
          commit('LOGIN_SUCCESS', token);
          dispatch('fetchFarmList');
        }
        return data;
      });
  },

  // Logs out the current user.
  logout({ commit }) {
    return service.logout().then(() => {
      commit('CLEAR_TOKEN');
      commit('SET_PROFILE', null);
    });
  },

  // 获取登录用户信息
  fetchProfile({ commit, dispatch, getters }) {
    return service.getProfile()
      .then((profile) => {
        commit('SET_PROFILE', profile);
        if (profile.dept === '新能源公司') {
          const arr = ['ALL', 'history', 'sec'];
          const serviceDataType = farm.state.dataTypes.filter((type) => arr.includes(type.name));
          commit('SET_DATA_TYPES', serviceDataType);
        }
        localStorage.setItem('CLOUD_DESKTOP_TOKEN', profile?.downloadToken || '');
        Object.defineProperty(window, 'CLOUD_DESKTOP_TOKEN', {
          get() {
            return localStorage.getItem('CLOUD_DESKTOP_TOKEN');
          },
          set(val) {
            localStorage.setItem('CLOUD_DESKTOP_TOKEN', val || '');
          },
        });
        return profile;
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          commit('SET_PROFILE', null);
        } else {
          console.warn(error);
        }
        return null;
      });
  },

};

export default {
  state,
  mutations,
  actions,
  getters,
};
