const state = {
  visitedViews: [],
};

const mutations = {
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some((v) => v.menuName === view.menuName)) return;
    // 首页的tab放在最前面
    if (view.isFirst) {
      state.visitedViews.unshift(
        { ...view },
      );
    } else {
      state.visitedViews.push(
        { ...view },
      );
    }
  },
  UPDATE_VISITED_VIEW: (state, view) => {
    const targetIndex = state.visitedViews.findIndex((v) => v.menuName === view.menuName);
    if (targetIndex !== -1) {
      state.visitedViews[targetIndex].query = view.query;
    }
  },

  DEL_VISITED_VIEW: (state, view) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.menuName === view.menuName) {
        state.visitedViews.splice(i, 1);
        break;
      }
    }
  },
};

const actions = {
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view);
  },
  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view);
  },
  delVisitedView({ commit, state }, view) {
    return new Promise((resolve) => {
      commit('DEL_VISITED_VIEW', view);
      resolve([...state.visitedViews]);
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
