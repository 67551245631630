<script>
import Layout from '@/views/layouts/main.vue';
import _ from 'lodash';
import { menusList } from '@/components/menus';

export default {
  name: 'IframeComponent',
  page: {
    title: '云端监控',
  },
  components: {
    Layout,
  },
  props: ['protoSearchOption'],
  data: () => {
    return {
      menusList,
    };
  },
  computed: {
    iframeUrl() {
      const routeName = this.$route.name;
      return this.getNode(menusList, routeName, 'menuName')?.url || '';
    },
  },
  methods: {
    getNode(tree, menuName, key) {
      if (Array.isArray(tree) && tree.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const node of tree) {
          if (node[key] === menuName) {
            return node;
          }
          if (node.child) {
            const result = this.getNode(node.child, menuName, key);
            if (result) {
              return result;
            }
          }
        }
      }
      return null;
    },
  },

};
</script>

<template>
  <Layout>
    <iframe :src="iframeUrl" frameborder="0" class="iframe-container" />
  </Layout>
</template>

<style lang="scss" scoped>
.iframe-container {
  width: 100%;
  height: calc(100vh - 100px);
}
</style>
