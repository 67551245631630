import { uikit } from '@/utils';
import router from '@/router/index';
import SharedService from '@sany/wsc-shared-service';

/*
前端鉴权(登录)流程：

流程一：
1. 整个 SPA 在启动时，route.beforeEach 拦截器中判断 App 状态是否已经完成初始化
2. App 状态未初始化，则 store.dispatch('initApp')，尝试调用 /api/profile 获取当前登录用户信息
3. App 状态初始化后，就确定了用户是否为登录状态，任何跳转都会经过 navigateTo 函数
4. navigateTo 中会检查路由是否需要登录(disableAuth)，如果需要登录，且没有登录，则调用 redirectToLogin

流程二：
1. 任何 API 调用都会经过以下 axios 的拦截器
2. 其中 axios 错误处理的拦截器中，如果检测到 401 HTTP 状态码
3.
  a) 如果调用的接口是 login 则提示 用户名密码错误；
  b) 如果当前页面是 publicPages 则什么都不做(publicPages 和 route.meta.disableAuth 作用重复)；
  c) 其他情况刷新当前页面，走 流程一
*/
const publicPages = [null, 'login'];
const onLoginExpired = () => {
  if (publicPages.includes(router.currentRoute.name)) {
    // 无需鉴权页面 不执行任何操作
  } else {
    // 接口返回状态码 401 表示未登录或登录失效，刷新页面，走流程一
    window.location.reload();
  }
};
const onAccessDenied = () => {
  if (publicPages.includes(router.currentRoute.name)) {
    // 无需鉴权页面 不执行任何操作
  } else {
    // 接口返回状态码 403 表示用户调用了接口越权
    uikit.warning('抱歉，您没有权限！');
  }
};

const checkDownloadPermission = () => localStorage.getItem('CLOUD_DESKTOP_TOKEN');

const options = {
  baseURL: '/',
  uikit,
  onLoginExpired,
  onAccessDenied,
  checkDownloadPermission,
};

const sharedServices = new SharedService(options);
const {
  authService,
  userService,
  farmService,
  faultService,
  otherService,
  modelService,
  reportService,
  cvtDataService,
  cmsService,
  protoTurbineService,
} = sharedServices.getServices();

export {
  authService,
  userService,
  farmService,
  faultService,
  otherService,
  modelService,
  reportService,
  cvtDataService,
  cmsService,
  protoTurbineService,
};
