import { initGlobalState, MicroAppStateActions } from 'qiankun';
import store from '@/store';

const initialState = { type: '', data: {} }; // 全局状态池给了个默认值
// MicroAppStateActions
const sharedActions = initGlobalState(initialState);

sharedActions.onGlobalStateChange((state, prev) => {
  if (state.type === 'addVisitedView') {
    store.dispatch(state.type, state.data);
    // 缓存
    // sharedActions.setGlobalState({
    //   app: "main",
    //   compName: state.data.comName,
    //   type: 'add',
    // });
  }
});
// shareActions.setGlobalState(initialState)
// shareActions.offGlobalStateChange()
export default sharedActions;
