<script>
export default {
  name: 'Pagination',
  props: {
    pageData: Object,
    gotoPage: Function,
    page: Number,
  },
  computed: {
    pageCount() {
      const { total, limit } = this.pageData;
      return Math.ceil(total / limit);
    },
    pageOffset() {
      const { page, limit } = this.pageData;
      return [(page - 1) * limit + 1, page * limit];
    },
  },
};
</script>

<template>
  <div v-if="pageData && pageData.length !== 0 && pageData.data" class="pagenation">
    <el-pagination
      background
      @current-change="gotoPage"
      :page-size="20"
      layout="total, prev, pager, next,jumper"
      :total="pageData.total"
      :current-page="page" />
  </div>
</template>

<style lang="scss">
.pagenation {
  margin-bottom: 10px;
  font-weight: normal;
  .webapp-input{
    input{
      height: 34px;
    }
  }
  button{
    width: 34px;
    border-radius: 4px !important;
    &:not([disabled]){
      color: #3E65FF !important;
    }
    >i{
      font-size: 16px !important;
    }
  }
  & .el-pager li {
    margin: 0 4px;
    border-radius: 4px !important;
  }
}
</style>
