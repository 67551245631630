<template>
  <div class="turbine-ops-manage-container">
    <div>
      <el-upload
        ref="uploadFile"
        class="upload-demo"
        :http-request="handleHttpRequest"
        :on-preview="handlePreview"
        :before-remove="beforeRemove"
        :on-change="handleUploadChange"
        :on-exceed="handleExceed"
        :show-file-list="false"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">
          点击上传
        </el-button>
        <div slot="tip" class="el-upload__tip">
          只能上传excel文件
        </div>
      </el-upload>
    </div>
    <div class="header-row first">
      <p class="title">
        分类结构
        <span class="red-color">（分类名称不能重复且不能为空）</span>
      </p>
      <!-- <el-switch
        v-model="isIncludeSub"
        inactive-text="显示子分类知识"
        inactive-color="#cdcdcd"
        active-color="#409eff"
      /> -->
    </div>
    <div class="header-row">
      <el-input
        v-model="filterText"
        placeholder="请输入分类名称..."
        style="width: 68%"
      />
      <el-button v-if="profile.role === 'admin' || profile.dept.includes('运维所')" type="primary" icon="el-icon-plus" circle @click="addRootCatalog" />
    </div>
    <el-tree
      ref="tree"
      class="filter-tree"
      :data="treeData"
      :current-node-key="currentNodeKey"
      node-key="id"
      :highlight-current="true"
      :expand-on-click-node="false"
      :props="{ label: 'name' }"
      :filter-node-method="filterNode"
      :render-content="renderContent"
      :default-expand-all="true"
      :draggable="false"
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      @node-click="handleNodeClick"
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
    />
    <el-dialog :title="formTitle" :visible.sync="modalShow" width="40%">
      <el-form ref="dataForm" status-icon :rules="rules" :model="catalog" label-position="top" style="width: 400px; margin-left:50px;">
        <el-form-item label="序号" prop="orderNo">
          <el-input v-model="catalog.orderNo" placeholder="请填写序号" type="number" :disabled="true" />
        </el-form-item>
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="catalog.name" placeholder="请填写分类名称" type="text" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCloseForm">
          取消
        </el-button>
        <el-button type="primary" @click="saveOrUpdate">
          提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { otherService as service } from '@/service';

export default {
  name: 'TurbineOpsManage',
  page: {
    title: '关键点控制管理',
  },
  components: {
  },
  data: () => {
    const validateTitle = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('分类名称不能为空!'));
      // eslint-disable-next-line prefer-regex-literals
      } else if (!new RegExp(/^(?!(\s+$))/).test(value)) {
        callback(new Error('分类名称不能全为空格!'));
      } else {
        callback();
      }
    };
    return {
      fileList: [],
      modalShow: false,
      treeLoading: true,
      isIncludeSub: true,
      treeData: [],
      filterText: '',
      currentNodeKey: '',
      currentNode: null,
      isAddRoot: false,
      catalog: {
        // orderNo: 1
      },
      formTitle: '',
      rules: {
        name: [
          { required: true, message: '分类名称不能为空！', trigger: 'blur' },
          { validator: validateTitle, trigger: 'blur' },
          {
            min: 1, max: 20, message: '分类名称长度不能超过 20', trigger: 'blur',
          },
        ],

      },

    };
  },
  computed: {
    ...mapGetters([
      'farm',
      'catelogs',
      'profile', // 用户权限信息
    ]),
    // treeData () {
    //   return this.catelogs
    // }
    // profile () {
    //   return this.$store.getters.profile
    // }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    isIncludeSub(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchTreeData();
      }
    },
    currentNodeKey(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$refs.tree.setCurrentKey(newValue);
      }
    },
  },
  created() {
    this.getCatelogsData();
  },
  methods: {
    ...mapActions([
      'getCatelogs',
    ]),
    ...mapMutations({
      pushCatalogs: 'PUSH_CATELOGS',
    }),
    async getCatelogsData() {
      const res = await this.getCatelogs();
      this.treeData = res || [];
    },
    handleHttpRequest(file) {
      const formData = new FormData();
      formData.append('user', 'user');
      formData.append('file', file.file);
      service.uploadWorklogsCatalog(formData).then((response) => {
        if (response) {
          this.$message('上传成功');
          this.fileList.push(file.file);
        } else {
          this.fileList = this.fileList.filter((item) => {
            return item.uid !== file.file.uid;
          });
        }
        this.getCatelogsData();
      }).catch((err) => {
        console.log(err);
        this.fileList = this.fileList.filter((item) => {
          return item.uid !== file.file.uid;
        });
      });
    },
    handleUploadChange(file, fileList) {
      // this.fileList = fileList
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    fetchTreeData() {
      service.loadCatalogTree(this.isIncludeSub).then((result) => {
        this.treeData = result;
        if (this.treeData.length === 0) {
          return;
        }
        this.currentNode = this.currentNode || this.treeData[0];
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(this.currentNode.id);
        });
        // this.onEditCatalog()
      }).finally(() => {
        this.treeLoading = false;
      });
    },

    // 节点过滤
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.name.indexOf(value) !== -1;
    },

    // 拖拽树节点
    handleDragStart(node, ev) {
      console.log('tree drag start', node);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.label);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.label, dropType);
    },
    // 节点拖拽放置
    handleDrop(draggingNode, dropNode, dropType, ev) {
      this.$confirm('确认更改分类树的结构顺序?', '提示', {
        type: 'warning',
      }).then(() => {
        service.dragCatalog(draggingNode.data, dropNode.data, dropType).then((result) => {
          this.$message.success(result);
        });
      }, () => {
        this.fetchTreeData();
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      return true;
    },
    allowDrag(draggingNode) {
      return true;
    },

    // 创建根节点
    addRootCatalog() {
      this.modalShow = true;
      // this.currentNode = null
      this.isAddRoot = true;
      this.formTitle = '创建根分类';
      let orderNo = 1;
      if (this.treeData.length > 0) {
        const numbers = this.treeData.map((ent) => ent.orderNo);
        const maxNum = Math.max(...numbers);
        orderNo = maxNum + 1;
      }
      this.catalog = {
        orderNo,
      };
    },

    // 添加分类
    async onAddCatalog(data) {
      this.isAddRoot = false;
      this.handleNodeClick(data);
      await this.$nextTick();
      this.onOpenForm();

      this.formTitle = `创建 ${this.currentNode.name} 的子分类`;
      let orderNo = 1;
      if (this.currentNode.children && this.currentNode.children.length > 0) {
        const numbers = this.currentNode.children.map((ent) => ent.orderNo);
        const maxNum = Math.max(...numbers);
        orderNo = maxNum + 1;
      }
      this.catalog = {
        orderNo,
        parent: this.currentNode.name,
        catalogId: this.currentNode.id,
      };
    },

    async onHiddeCatalog(data) {
      if (data.id) {
        const result = await service.updateWorklogsCatalog(data.id, {
          isHidden: !data.isHidden,
          name: data.name,
          hiddenCatalog: true,
        });
        this.$set(this.currentNode, 'isHidden', result.isHidden);
        await this.getCatelogsData();
        this.catalog = {};
        this.$message.success('隐藏分类成功');
      }
    },
    // 编辑分类
    onEditCatalog(data) {
      this.handleNodeClick(data);
      // this.modalShow = true;
      this.onOpenForm();
      this.formTitle = '编辑分类';
      // this.catalog = Object.assign({}, data);
      this.catalog = JSON.parse(JSON.stringify(this.currentNode));
    },

    // 打开表单
    onOpenForm() {
      this.modalShow = true;
      this.catalog.title = '';
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate();
      });
    },

    // 新增或者编辑节点
    saveOrUpdate() {
      // eslint-disable-next-line consistent-return
      this.$refs.dataForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (this.catalog.id) {
          service.updateWorklogsCatalog(this.catalog.id, this.catalog).then((result) => {
            this.updateNode(result);
            this.catalog = {};
            this.$message.success('更新成功');
          });
        } else {
          service.addWorklogsCatalog(this.catalog).then((result) => {
          // this.fetchTreeData()
            this.appendNode(result);
            // this.getCatelogs()
            this.catalog = {};
            this.$message.success('创建成功');
          });
        }
        this.modalShow = false;
      });
    },

    // 添加节点
    appendNode(newNode) {
      // 如果是根节点
      if (this.isAddRoot) {
        // this.pushCatalogs(newNode)
        this.treeData.push(newNode);
      } else {
        if (!this.currentNode.children) {
          this.$set(this.currentNode, 'children', []);
        }
        this.currentNode.children.push(newNode);
      }
    },

    // 更新树节点
    updateNode(newNode) {
      if (this.currentNode) {
        console.error(newNode);
        this.$set(this.currentNode, 'name', newNode.name);
      }
    },

    // 删除分类
    onRemoveCatalog(node, data) {
      this.$confirm('确认删除分类信息?', '提示', {
        type: 'warning',
      }).then(() => {
        service.delWorklogsCatalog(data.id).then((res) => {
          if (res) {
            // this.fetchTreeData()
            // 如果删除的节点有父节点，则更新父节点children
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            children.forEach((d) => {
              if (d.id === data.id) {
                this.$refs.tree.remove(d);
                // children.splice(index, 1)
              }
            });

            // 非根节点
            let currentNode = null;
            if (parent.data.children) {
              currentNode = parent.data;
            } else {
              // 根节点
              currentNode = parent.data[0];
            }
            this.handleNodeClick(currentNode);
          }
        });
      });
    },

    // 点击节点
    handleNodeClick(data = {}) {
      this.currentNode = data;
      this.currentNodeKey = data.id;
      // this.onEditCatalog()
    },

    // 节点按钮
    renderContent(h, { node, data }) {
      if (this.profile.role === 'admin' || this.profile.dept.includes('运维所')) {
        return (
          <span class="tree-node">
            <span class={{ 'text-ellipsis': true, 'is-hidden': (data.isHidden === 1) }}>
              {node.label}
              {' '}
              (
              {data.children?.length || 0}
              )
            </span>
            <span v-if="role === 'admin'">
              <el-button size="mini" type="text" on-click={() => this.onHiddeCatalog(data)}>
                <i class="el-icon-view" />
              </el-button>
              <el-button size="mini" type="text" on-click={() => this.onAddCatalog(data)}>
                <i class="el-icon-plus" />
              </el-button>
              <el-button size="mini" type="text" on-click={() => this.onEditCatalog(data)}>
                <i class="el-icon-edit" />
              </el-button>
              <el-button size="mini" type="text" on-click={() => this.onRemoveCatalog(node, data)}>
                <i class="el-icon-delete" />
              </el-button>
            </span>
          </span>
        );
      }
      return (
        <span class="tree-node">
          <span class="text-ellipsis">
            {node.label}
            {' '}
            (
            {data.itemCount || 0}
            )
          </span>
        </span>
      );
    },

    // 关闭表单
    onCloseForm() {
      this.catalog = {};
      this.modalShow = false;
    },

  },
};
</script>

<style lang="scss" scoped>
.turbine-ops-manage-container {
  padding-left: 20px;
  min-width: 400px;
  .red-color {
    font-size: 12px;
    color: red;
  }
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    &.first {
      padding-bottom: 14px;
      border-bottom: 1px dashed $info;
    }
  }
  ::v-deep .text-ellipsis {
    padding-right: 20px;
    font-size: 16px;
    &.is-hidden {
      text-decoration: line-through;
    }
  }
  .title {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: #3b3f5c;
  }
  .content {
    flex: 1;
    height: 100%;
    // padding: 20px;
    background-color: #f5f8f8;
    box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 17%);
    .form-wrapper {
      .form-action {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
