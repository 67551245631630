import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import routes from './routes';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'page',
});
const router = new VueRouter({
  routes,
  // TODO
  mode: 'history',
  // TODO: scrollBehavior
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
// router.beforeEach((to, from, next) => {
//   next();
// });
// // TODO: router.beforeResolve

// router.afterEach((routeTo, routeFrom) => {
//   // NProgress.done();
// });

router.onError((error) => {
  console.log(error, 'error');
});

export default router;
