<script>
export default {
  // TODO: 根配置
  name: 'App',
};
</script>

<template>
  <div>
    <RouterView default="{name: '/'}" />
  </div>
</template>

<!-- 全局样式定义在此处 -->
<style lang="scss">
// TODO: 通用样式变量定义
*,
*::before,
*::after {
  box-sizing: border-box;
}
</style>
