import moment from 'moment';
import { queryParamsUtils } from '@sany/web-utils';

export default {
  props: ['type', 'farmStatusData', 'selectedDataType', 'isLoading', 'fileTypeList', 'isCms'],
  data() {
    const params = queryParamsUtils.getParamsQuery();
    const currentDate = params.currentDate ? moment(params.currentDate) : moment().toDate();
    return {
      currentDate,
      hideEmptyFarm: true,
    };
  },
  computed: {
    daysOfMonth() {
      if (this.currentDate) {
        const monthDate = new Date(this.currentDate);
        const days = new Date(
          monthDate.getFullYear(),
          monthDate.getMonth() + 1,
          0,
        ).getDate();
        return days;
      }
      return '';
    },
    farmList() {
      if (this.hideEmptyFarm) {
        const farms = [];
        // eslint-disable-next-line array-callback-return
        this.$store.state.farm.list.map((f) => {
          const farmStatus = this.farmStatusData.filter((ent) => ent.farm.farmId === f.farmId)[0];
          if (farmStatus) {
            if (farmStatus.dates.filter((day) => day !== null).length > 0) {
              farms.push(f);
            }
          }
        });
        return farms;
      }
      return this.$store.state.farm.list;
    },
  },
  methods: {
    getFarmTurbines(farm) {
      const farms = this.farmList.filter(
        (f) => f.pinyinCode === farm.pinyinCode,
      );
      if (farms[0]?.turbinesCount) {
        return farms[0].turbinesCount;
      }
      return farms.length > 0 && farms[0].turbines ? farms[0].turbines.length : 0;
    },
    changeMonth(m) {
      if (m === -1) {
        this.currentDate = moment(this.currentDate).subtract(1, 'months').format('YYYY-MM');
      } else {
        this.currentDate = moment(this.currentDate).add(1, 'months').format('YYYY-MM');
      }
      this.onChangeMonthEvent();
    },
    showFarmRow(farmStatusData) {
      if (!this.hideEmptyFarm) {
        return true;
      }
      return farmStatusData.dates.filter((day) => day !== null).length > 0;
    },
    onChangeMonthEvent() {
      this.$emit('changeMonth', moment(this.currentDate).format('YYYY-MM'));
    },
    convertToCamelCase(str) {
      const reg = /-(.)/g;
      return str.replace(reg, (fullMatch, g1, index) => {
        if (index === 0) return g1;
        return g1.toUpperCase();
      });
    },
    computeFarmStatus(farm, fs) {
      if (!fs) {
        return 'empty';
      }
      let cosCount = fs.cosTurbineCount;
      if (this.selectedDataType) {
        if (this.selectedDataType.name === 'ALL') {
          if (fs.anyFileTypeTurbineCount === 0) return 'empty';
          if (fs.anyFileTypeTurbineCount >= fs.turbineCount) return 'full';
          return 'incomplete';
        }
        // 如果是变流器需要判断选中的大类
        if (this.type === 'cvt' && this.selectedDataType.name !== fs.cvtBrand && this.selectedDataType.parentNode !== fs.cvtBrand) {
          cosCount = 0;
          // eslint-disable-next-line consistent-return
          return;
        }
        this.fileTypeList.forEach((type) => {
          if (this.selectedDataType.name === 'ems_log') {
            cosCount = fs.emsTurbineCount;
            return;
          }
          if (this.selectedDataType.name.includes('-')) {
            const typeStr = this.convertToCamelCase(this.selectedDataType.name);
            cosCount = fs[`${typeStr}TurbineCount`];
            return;
          }
          if (this.selectedDataType.name === type) {
            cosCount = fs[`${type}TurbineCount`];
          }
        });
      }
      if (cosCount === 0 || cosCount === undefined) {
        return 'empty';
      }
      if (cosCount === fs.turbineCount || cosCount >= fs.turbineCount) {
        return 'full';
      }
      return 'incomplete';
    },

    gotoTurbinePage(farm) {
      const params = {
        pinyinCode: farm.pinyinCode,
        currentDate: moment(this.currentDate).format('YYYY-MM'),
      };
      switch (this.type) {
        case 'cms':
          this.$router.push({ name: 'cmsDataTurbineExplorer', params });
          break;
        case 'cvt':
          this.$router.push({ name: 'cvtDataTurbineExplorer', params });
          break;
        default:
          this.$router.push({ name: 'turbineExplorer', params });
      }
    },
    farmNameClass(farm) {
      const currentDate = moment().format('YYYY-MM-DD');
      const lastyearData = moment(currentDate).add(-1, 'year').format('YYYY-MM-DD');
      if (farm.gridConnectedDate && moment(farm.gridConnectedDate).isBetween(lastyearData, currentDate)) {
        return 'isWithinYear';
      }
      return '';
    },
  },
};
