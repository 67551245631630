import { computed, defineComponent, ref } from 'vue';
import router from '@/router/index';
import store from '@/store';
// vue3 写法
export default defineComponent({
  name: 'UserProfile',

  setup() {
    // 个人信息
    const profile = computed(() => store.state.auth.profile);

    const role = ref({
      service_company: '服务公司',
      research_institute: '研究院内部',
      model_developer: '模型开发者',
      internal_operation: '内部运维',
      market_ops: '营销运维',
      admin: '管理员',
    });
    // 退出
    const logout = () => {
      store.dispatch('logout').then(() => {
        router.push({ name: 'login' });
        window.location.href = '/';
      });
    };
    return {
      profile,
      role,
      logout,
    };
  },
});
