<template>
  <div class="scrollbar-container">
    <div ref="wrapper" class="custom-scrollbar-wrapper scrollbar-wrapper">
      <slot>test</slot>
      <div ref="vertical" class="custom-vertical-scrollbar">
        <div class="custom-vertical-indicator" />
      </div>
      <!-- <div ref="horizontal" class="custom-horizontal-scrollbar">
        <div class="custom-horizontal-indicator" />
      </div> -->
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core';
import ScrollBar from '@better-scroll/scroll-bar';
import MouseWheel from '@better-scroll/mouse-wheel';

BScroll.use(ScrollBar);
BScroll.use(MouseWheel);

export default {
  name: 'ScrollBar',
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.initBscroll();
    });
  },
  beforeDestroy() {
    if (this.scroll) {
      this.scroll.destroy();
    }
  },
  methods: {
    initBscroll() {
      this.scroll = new BScroll(this.$refs.wrapper, {
        scrollX: false,
        scrollY: true,
        click: true,
        mouseWheel: true,
        scrollbar: {
          customElements: [this.$refs.vertical],
          fade: true,
          interactive: true,
          scrollbarTrackClickable: true,
        },
      });
      const hooks = this.scroll.scroller.actionsHandler.hooks;
      hooks.on('click', async (event) => {
        const timerId = setTimeout(() => {
          this.scroll.refresh();
          clearTimeout(timerId);
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar-container {
  height: 100%;

  .custom-scrollbar-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden!important;
  }
  // .custom-scrollbar-content
  //   display inline-block
  //   height 280px
  .custom-horizontal-scrollbar{
    position:absolute;
    left: 50%;
    bottom: 10px;
    width: 100px;
    height: 7px;
    border-radius: 6px;
    transform: translateX(-50%) translateZ(0);
    background-color: rgb(200, 200, 200, 0.3);
  }
  .custom-horizontal-indicator{
    height: 100%;
    width: 20px;
    border-radius: 6px;
    background-color: #1b55e2;
  }
  .custom-vertical-scrollbar{
    position: absolute;
    top: 50%;
    right: 10px;
    height: 100px;
    width: 7px;
    border-radius: 6px;
    transform: translateY(-50%) translateZ(0);
    background-color: rgb(200, 200, 200, 0.3);
  }
  .custom-vertical-indicator {
    width: 100%;
    height: 20px;
    border-radius: 6px;
    background-color: #1b55e2;
  }

}
</style>
