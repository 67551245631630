// TODO: 如果为了跟踪用户访问了什么功能，这种映射转换为什么不直接做到 menu.json 中
export const routeNamesfnList = [
  {
    routeNames: ['faultAnalySearch'],
    fn: '首发故障',
  },
  {
    routeNames: ['faultManage'],
    fn: '日清日结',
  },
  {
    routeNames: ['farmExplorer', 'turbineExplorer'],
    fn: '数据下载',
  },
  {
    routeNames: ['dailyReport'],
    fn: '日报',
  },
  {
    routeNames: ['monthlyReport'],
    fn: '月报',
  },
  {
    routeNames: ['weeklyReport'],
    fn: '周报',
  },
  {
    routeNames: ['freeReport'],
    fn: '自由报表',
  },
  {
    routeNames: ['serviceReport'],
    fn: '服务报表',
  },
  {
    routeNames: ['performanceReport'],
    fn: '性能报表',
  },
  {
    routeNames: ['farmModelSummary', 'turbineModelResult'],
    fn: '模型预警',
  },
  {
    routeNames: ['modelManager'],
    fn: '模型管理',
  },
  {
    routeNames: ['phmModelWarning'],
    fn: 'PHM报警',
  },
  {
    routeNames: ['scheduleJob'],
    fn: '任务调度',
  },
  {
    routeNames: ['scheduleTask'],
    fn: '模型分析',
  },
  {
    routeNames: ['modelResultInvestigate'],
    fn: '故障排查',
  },
  {
    routeNames: ['accessStatistics'],
    fn: '访问统计',
  },
  {
    routeNames: ['farmsControlOverview'],
    fn: '主控运维',
  },
  {
    routeNames: ['routeNamesfnList'],
    fn: '样机分析',
  },
  {
    routeNames: ['energyManagement'],
    fn: '能管分析',
  },
  {
    routeNames: ['energyManagement'],
    fn: '能管分析',
  },
  {
    routeNames: ['zhishi'],
    fn: '运维知识库',
  },
  {
    routeNames: ['cmsReport'],
    fn: 'CMS数据下载',
  },

];
export default {};
