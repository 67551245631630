// import service from '@/service'
import axios from 'axios';

export const state = {
  dics: [],
  sidebarIsCollapse: false,
  breadcrumbs: [],
  isLoading: false,
  // 默认外网
  isInternetAvaliabale: false,
};

export const mutations = {
  SET_DICS(state, list) {
    state.list = list;
  },
  SET_SIDEBAR_STATE(state) {
    state.sidebarIsCollapse = !state.sidebarIsCollapse;
  },
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_INTERNET_STATUS(state, isInternetAvaliabale) {
    // isInternetAvaliabale true 内网  false 外网
    state.isInternetAvaliabale = isInternetAvaliabale;
  },
};

export const actions = {

  toggleSidebar({ commit }, val) {
    commit('SET_SIDEBAR_STATE', val);
  },

  setBreadcrumbs({ commit }, { breadcrumbs }) {
    commit('SET_BREADCRUMBS', breadcrumbs);
  },

  setLoading({ commit }, isLoading) {
    commit('SET_LOADING', isLoading);
  },

  // app 初始化
  initApp({ commit, dispatch, getters }) {
    return Promise.all([
      dispatch('detectEnv'),
      dispatch('fetchProfile'),
      dispatch('fetchFarmList'),
      dispatch('fetchModels'),
      dispatch('fetchModelInfos'),
    ]);
  },

  detectEnv({ commit, dispatch, getters }) {
    const http = axios.create({
      validateStatus(status) {
        // 内网 isInternetAvaliabale: !!status true 内网, isInternetAvaliabale: !!status false 外网
        commit('SET_INTERNET_STATUS', !!status);
        return true;
      },
    });
    // const url = window.location;
    // http.get('https://inner-dc.sanywind.net/').then(() => {
    //   console.log('内网可以访问');
    //   if (url.host === 'platform.dc.sanywind.net') {
    //     // 当内网可以访问时 platform.dc.sanywind.net 也需要进行验证
    //     commit('SET_INTERNET_STATUS', false);
    //   }
    // });
    const url = window.location;
    if (url.host === 'platform.dc.sanywind.net') {
      http.get('https://inner-dc.sanywind.net/').then(() => {
        window.location.href = window.location.href.replaceAll('platform.dc.sanywind.net', 'inner-dc.sanywind.net');
      });
    } else {
      http.get('https://inner-dc.sanywind.net/');
    }
  },

  // 获取字典列表
  fetchDics({ commit, dispatch, getters }) {
    // return service.fetchFarmList()
    //   .then( (data) => {
    //     console.log('fetch farm list =', data);
    //     commit('FETCH_FARM_LIST', data);
    //     return data;
    //   });
  },

};

export default {
  state,
  mutations,
  actions,
};
