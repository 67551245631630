import _ from 'lodash';
import {
  VideoIcon,
  HomeIcon,
  LayersIcon,
  BoxIcon,
  GridIcon,
  MapIcon,
  FileTextIcon,
  UsersIcon,
  BookIcon,
  CalendarIcon,
  CodepenIcon,
  BookmarkIcon,
  LifeBuoyIcon,
  FileMinusIcon,
  CpuIcon,
  DatabaseIcon,
  TrendingUpIcon,
  ArrowUpCircleIcon,
  AlertTriangleIcon,
  AirplayIcon,
  SendIcon,
  FilterIcon,
  FeatherIcon,
  BatteryChargingIcon,
  ColumnsIcon,
  ChevronsUpIcon,
  KeyIcon,
  ArrowDownCircleIcon,
  ShoppingBagIcon,
  RefreshCwIcon,
  ActivityIcon,
  TvIcon,
} from 'vue-feather-icons';

const iconMap = {
  VideoIcon,
  HomeIcon,
  LayersIcon,
  BoxIcon,
  GridIcon,
  MapIcon,
  FileTextIcon,
  UsersIcon,
  BookIcon,
  CalendarIcon,
  CodepenIcon,
  BookmarkIcon,
  LifeBuoyIcon,
  FileMinusIcon,
  CpuIcon,
  DatabaseIcon,
  TrendingUpIcon,
  ArrowUpCircleIcon,
  AlertTriangleIcon,
  AirplayIcon,
  SendIcon,
  FilterIcon,
  FeatherIcon,
  BatteryChargingIcon,
  ColumnsIcon,
  ChevronsUpIcon,
  KeyIcon,
  ArrowDownCircleIcon,
  ShoppingBagIcon,
  RefreshCwIcon,
  ActivityIcon,
  TvIcon,
};
const {
  CONTROL_MENUS_LIST,
  DC_MENUS_LIST,
} = window.SANY_SETTINGS;

export const menusList = (() => {
  // eslint-disable-next-line no-restricted-globals
  const isInner = location.host !== 'platform.dc.sanywind.net';
  // 不是内网，删掉CMS案例库菜单
  if (!isInner) {
    const CMSIndex = _.findIndex(DC_MENUS_LIST, (item) => item.menuName === 'CMS');
    if (CMSIndex === -1) {
      return DC_MENUS_LIST;
    }
    const caseListIndex = _.findIndex(DC_MENUS_LIST[CMSIndex].child, (item) => item.menuName === 'caseList');
    if (caseListIndex === -1) {
      return DC_MENUS_LIST;
    }
    DC_MENUS_LIST[CMSIndex].child.splice(caseListIndex, 1);
    return DC_MENUS_LIST;
  }
  return DC_MENUS_LIST;
})();

export const controlMenusList = CONTROL_MENUS_LIST.map((menu) => {
  if (menu.icon) {
    return {
      ...menu,
      icon: iconMap[menu.icon] || GridIcon,
    };
  }
  return menu;
});

export default {};
