<script>
import { otherService as service } from '@/service';

export default {
  name: 'Changelog',
  page: {
    title: '更新日志',
  },
  data() {
    return {
      changelog: [],
    };
  },
  profile() {
    return this.$store.state.auth.profile;
  },
  mounted() {
    service.fetchChangelog().then((changelog) => {
      this.changelog = changelog;
    });
  },
};
</script>

<template>
  <Layout>
    <ul>
      <li v-for="log in changelog" :key="log.date" style="margin: 10px;">
        <p>
          版本:{{ log.version }} <span style="font-size: 12px;">({{ log.date }})</span>
        </p>
        <p style="white-space: pre-line;">
          {{ log.content }}
        </p>
      </li>
    </ul>
  </Layout>
</template>

<!-- 全局样式定义在此处 -->

<style lang="scss">
</style>
