<script>
import SideBar from '@/components/Sidebar.vue';
import TagsView from '@/components/TagsView/index.vue';
import { WaterMark } from '@sany/water-mark';
import { start } from 'qiankun';
import Navbar from '@/views/navbar/index.vue';
import store from '@/store';
import validBrowserVersion from '@/utils/bowser';
import sharedActions from '@/shared/actions';

export default {
  // TODO: 根配置
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Main',
  components: {
    WaterMark,
    Navbar,
    SideBar,
    TagsView,
  },
  data() {
    return {
      include: [],
      currentMenuName: '', // 当前选择的菜单名称
    };
  },
  computed: {
    isCollapse() {
      return store.state.app.sidebarIsCollapse;
    },
    profile() {
      return store.state.auth.profile;
    },
  },
  mounted() {
    // 开启沙盒模式
    if (!window.qiankunStarted) {
      window.qiankunStarted = true;
      start({
        prefetch: (app) => app.name === 'main', // 正对特地的应用开启预先加载
      });
    }
    validBrowserVersion();
    this.initMenuName();
    sharedActions.onGlobalStateChange((state, prev) => {
      const { type: currentType, compName: currentCompName } = state;
      const { type: prevType, compName: prevCompName } = prev;
      if (currentType === prevType && currentCompName !== prevCompName && currentType === 'add' && currentCompName === 'FaultEventAnalysis') {
        this.currentMenuName = 'faultEventAnalysis';
      }
    });
  },
  methods: {
    getMainInclude(compName) {
      if (!this.include.includes(compName)) {
        this.include.push(compName);
      }
    },
    minusMainInclude(compName) {
      const index = this.include.indexOf(compName);
      if (index > -1) {
        this.include.splice(index, 1);
      }
    },

    // 初始当前的菜单信息
    initMenuName() {
      const currentPath = this.$route.path;
      const visitedViews = this.$store.state.tagsView.visitedViews;
      this.currentMenuName = visitedViews.find((item) => item.route === currentPath).menuName;
    },

    // 修改当前菜单名称
    updateMenuName(menuName) {
      this.currentMenuName = menuName;
    },
  },
};
</script>

<template>
  <div>
    <Navbar @getMainInclude="getMainInclude" />
    <!--  BEGIN MAIN CONTAINER  -->
    <div id="container" class="main-container" :class="isCollapse ? 'hiddenSidebar' : 'openSidebar'">
      <div class="overlay" />
      <div class="search-overlay" />
      <SideBar @getMainInclude="getMainInclude" @updateMenuName="updateMenuName" />
      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <TagsView @minusMainInclude="minusMainInclude" :currentMenuName="currentMenuName" @updateMenuName="updateMenuName" />
        <div class="layout-px-spacing">
          <WaterMark :profile="profile">
            <div class="row layout-top-spacing">
              <KeepAlive :include="include">
                <RouterView :key="$route.fullPath" />
              </KeepAlive>
              <div id="appContainer" style="width: 100%" />
            </div>
            <!-- <div class="feedback-box">
              <FeedbackBtn />
            </div> -->
          </WaterMark>
        </div>
      </div>
    </div>
    <!-- TODO -->
  </div>
</template>
<style lang="scss" scoped>
// .layout-px-spacing {
//   margin-top: 56px;
//   position: relative;
//   background: #fff
// }
</style>
