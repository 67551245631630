<script>
import Navbar from '@/views/navbar/index.vue';
import { authService as service } from '@/service';
import { uikit } from '@/utils';

export default {
  name: 'Regist',
  components: {
    Navbar,
  },
  data: () => {
    return {
      isDisable: false,
      user: {
        nickname: '',
        dept: '',
        role: '',
        farms: [],
      },
    };
  },
  computed: {
    roles() {
      return this.$store.state.user.roles.list.filter((item) => item.value !== 'admin');
    },
    farms() {
      return this.$store.state.farm.list;
    },
  },
  mounted() {
    console.log(this.roles);
  },

  methods: {
    submitUser() {
      if (!this.user.nickname) {
        uikit.error('请输入昵称！');
        return;
      }
      if (!this.user.dept) {
        uikit.error('请输入部门！');
        return;
      }
      if (!this.user.role) {
        uikit.error('请选择角色！');
        return;
      }
      if (this.user.role.value === 'service_company') {
        if (!this.user.farms.length) {
          uikit.error('请输入您所属的风场，逗号分割！');
          return;
        }
        this.user.farms = this.user.farms.split(/[，,]/);
      }
      this.isDisable = true;
      service.registUser(this.user).then((user) => {
        this.isDisable = false;
        if (user.registerInfo) {
          uikit.message('申请成功，请等待管理员审核！审核完成会飞书消息通知您！');
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <Navbar class="header-box" />
    <div class="row">
      <form class="form-wrapper">
        <fieldset>
          <legend>注册信息</legend>
          <div class="form-group-mt">
            <label>姓名</label>
            <el-input v-model="user.nickname" placeholder="请输入您的姓名" />
          </div>
          <div class="form-group-mt">
            <label>三级部门</label>
            <el-input v-model="user.dept" placeholder="请输入您的部门" />
          </div>
          <div class="form-group-mt">
            <label>角色</label>
            <el-select v-model="user.role" placeholder="请选择用户角色" style="width: 100%">
              <el-option
                v-for="item in roles"
                :key="item.value"
                :label="item.name"
                :value="item.value" />
            </el-select>
          </div>
          <div v-if="user.role.value === 'service_company'" class="form-group">
            <label>所属风场</label>
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入所属风场(最多5个)，逗号分割"
              v-model="user.farms"
            />
          </div>
        </fieldset>
        <div class="form-action">
          <el-button
            size="medium"
            type="primary"
            :disabled="isDisable"
            @click="submitUser"
          >
            提交
          </el-button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.header-box{
  padding-left:0;
  .header.navbar{
    padding: 0!important;
  }
}
</style>

<style lang="scss" scoped>

.form-wrapper{
  width: 400px;
  padding: 20px 0;
  margin:97px auto 0;

  .form-group-mt {
    margin-bottom: 20px;
  }
  fieldset{
    padding: 20px 30px;
    border: 2px solid #ccc;
  }
  legend{
    width: auto;
    margin-bottom: 0;
  }
  .form-action{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
