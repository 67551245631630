import './global';
import Vue from 'vue';
import _ from 'lodash';
import 'moment-duration-format';
// ElementUI 的 Upload 组件，用于上传分析功能
import ElementUI from 'element-ui';

import ElScrollbar from 'element-ui/lib/scrollbar';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import router from '@/router';
import store from '@/store';
import { filters } from '@/utils';
import { userService as service } from '@/service';
import { routeNamesfnList } from '@/pathFn';
import Viewer from 'v-viewer';
import { registerMicroApps, start } from 'qiankun';
import './shared/actions';
import { queryParamsUtils } from '@sany/web-utils';
import App from './App.vue';
import '@/assets/scss/element-variables.scss';
// import 'element-ui/lib/theme-chalk/index.css';
import 'viewerjs/dist/viewer.css';
import '@/assets/iconfont/iconfont.css';
import '@sany/font/iconfont.css';

queryParamsUtils.setReplaceFn(router.replace.bind(router));

if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true;
}

Vue.use(ElScrollbar);
Vue.prototype._ = _;
Vue.prototype.get = _.get;
Vue.use(ElementUI);

Vue.use(Viewer);
Viewer.setDefaults({
  Options: {
    zIndex: 9999, inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source',
  },
});
Vue.config.silent = true;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
function redirectToLogin(to, from, next) {
  next({ name: 'login', query: { redirect: to.fullPath } });
}

let appInited = false;
function navigateTo(to, from, next) {
  if (to.name === 'login') {
    next();
    return;
  }
  if (!store.getters.isLoggedIn) {
    redirectToLogin(to, from, next);
    return;
  }
  if (to.matched.some((route) => !route.meta.disableAuth)) {
    if (!store.getters.isLoggedIn) {
      redirectToLogin(to, from, next);
    } else {
      next();
    }
  } else {
    next();
  }
}
function hasClass(ele, cls) {
  return ele.className.match(new RegExp(`(\\s|^)${cls}(\\s|$)`));
}

function addClass(ele, cls) {
  if (!hasClass(ele, cls)) {
    ele.className += ` ${cls}`;
  }
}

function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp(`(\\s|^)${cls}(\\s|$)`);
    ele.className = ele.className.replace(reg, ' ');
  }
}
function switchTheme(to) {
  try {
    const bodyEle = document.getElementsByTagName('body')[0];
    if (to.matched.some((route) => route.meta.theme)) {
      const theme = to.meta.theme;
      // toggleClass(bodyEle, theme);
      addClass(bodyEle, theme);
    } else {
      removeClass(bodyEle, 'dark');
    }
  } catch (error) {
    console.log('切换主题失败');
  }
}

function getMenu(arr, key, val) {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i].child && arr[i].child.length > 0) {
      const result = getMenu(arr[i].child, key, val);
      if (result) {
        return result;
      }
    }
    if (arr[i][key] === val) {
      return arr[i];
    }
  }
  return null;
}

router.beforeEach((to, from, next) => {
  const menuName = (getMenu(window.SANY_SETTINGS.DC_MENUS_LIST, 'path', from.path) || {}).menuName;
  if (menuName) {
    store.dispatch('updateVisitedView', {
      ...from,
      menuName,
    });
  }
  service.viewPage(to).then(() => {
    // TODO: UserFn 还有用吗？
    const routeName = routeNamesfnList.filter((item) => item.routeNames.includes(to.name))[0];
    const fn = routeName ? routeName.fn : [];
    if (fn.length > 0) {
      const option = {
        routeName: to.name,
        fn,
      };
      service.createUserFnCount(option);
    }
  });
  switchTheme(to);
  if (to?.path.indexOf('/micro/main') < 0) {
    // 主应用 兼容 breadcrumbs 可以从 router.meta 获取，也可以从 store 获取
    store.dispatch('setBreadcrumbs', { breadcrumbs: [] });
  }
  if (!appInited) {
    store.dispatch('initApp').then(() => {
      console.log('init app done');
    }).finally(() => {
      appInited = true;
      navigateTo(to, from, next);
    });
  } else {
    navigateTo(to, from, next);
  }
});

const release = `wsc-v1-web@${process.env.npm_package_version}`;
console.log(`release: ${release}`);

Sentry.init({
  Vue,
  release,
  // TODO: 做成配置
  dsn: 'https://b6e4393372b04718b60f408178a006c4@platform.dc.sanywind.net:31119/5',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // tracingOrigins: ['localhost', 'my-site-url.com', /^\//]
      tracingOrigins: ['*'],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

console.log(process.env.VUE_APP_MICRO_BI, 'process.env.VUE_APP_MICRO_BI');
// TODO: 为什么只加载 main
// 注册微应用app
registerMicroApps([{
  name: 'main',
  entry: process.env.VUE_APP_MICRO_MAIN,
  container: '#appContainer',
  // micro 对应嵌套二级菜单
  activeRule: '/micro/main',
  props: {
    data: {
      farm: store.state.farm,
    },
    router,
  },
}, {
  name: 'bi',
  entry: process.env.VUE_APP_MICRO_BI,
  container: '#appContainer',
  // micro 对应嵌套二级菜单
  activeRule: '/micro/bi',
  props: {
    router,
  },
}, {
  name: 'chonky',
  entry: process.env.VUE_APP_MICRO_CHONKY,
  container: '#appContainer',
  // micro 对应嵌套二级菜单
  activeRule: '/micro/chonky',
}], {
  beforeLoad: (app) => {
    console.log('before load', app.name);
    // Loading.service({ fullscreen: true })
  },
  afterMount: () => {
    // loadingInstance.close()
  },
  beforeMount: [(app) => {
    console.log('before mount', app.name);
  }],
});
