import { MessageBox } from 'element-ui';
import Bowser from 'bowser';

const validBrowserVersion = () => {
  const browser = Bowser.getParser(window?.navigator?.userAgent || 'UserAgent');

  const hasExistValidBrowserVersion = window.localStorage.getItem('hasExistValidBrowserVersion');

  if (hasExistValidBrowserVersion) {
    return;
  }

  const browserInfo = browser.getBrowser();

  const isLowVersion = browser.satisfies({
    // declare browsers per OS
    windows: {
      chrome: '<90',
    },
    // macos: {
    //   chrome: '<117',
    // },
  });

  window.localStorage.setItem('hasExistValidBrowserVersion', true);
  // 浏览器版本不符合
  if (isLowVersion) {
    const browserFileUrl = '/cloud_web/ChromeStandaloneSetup64.exe';
    MessageBox.confirm(`检测到您的${browserInfo.name}浏览器版本过低，请点击<strong><a href="/api/v3/file/exchange?key=${browserFileUrl}" target="__blank" style="color: #409EFF;">下载</a></strong>，更新浏览器来获取更好的体验，谢谢`, '提示', {
      dangerouslyUseHTMLString: true,
    }).then((res) => {
      window.open(`/api/v3/file/exchange?key=${browserFileUrl}`);
    });
  }

  console.log(isLowVersion, 'isLowVersion');
};

export default validBrowserVersion;
