import { Trash2Icon } from 'vue-feather-icons';
import moment from 'moment';
import appConfig from '@/app.config.json';
import Pagination from '@/components/Pagination';
import { uikit } from '@/utils';
import { faultService as service } from '@/service/index';

export default {
  name: 'UserHistory',
  page: {
    title: '故障浏览记录',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Pagination,
    Trash2Icon,
  },
  data: () => {
    return {
      pageData: {
        data: [],
        page: 1,
        limit: 20,
        total: 0,
      },
      isCheckAll: false,
      selectedRowIds: [],
      currentComment: null,
      isLoading: true,
    };
  },
  computed: {},
  watch: {
    // 监听选择行改变之后 全选状态修改
    selectedRowIds: {
      handler() {
        if (this.selectedRowIds.length === this.pageData.data.length) {
          this.isCheckAll = true;
        } else {
          this.isCheckAll = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadHistory(1);
  },
  methods: {
    loadHistory(page = 1) {
      const postData = { page };
      service.fetchFaultAnalyHistory(postData).then((page) => {
        this.pageData = page;
        this.isLoading = false;
      });
    },
    gotoPage(i) {
      this.loadHistory(i);
    },
    showComment(fault) {
      this.currentComment = fault.comment;
      this.$modal.show('comment');
    },
    goToTool(item) {
      // const routeData = this.$router.resolve({ name: 'tool', params: { faultId: item.id } })
      const routeData = this.$router.resolve({
        name: 'firstFaultAnalyze',
        query: {
          firstFaultId: item.id,
          turbineId: item.turbineId,
        },
      });
      window.open(routeData.href, '_blank');
    },
    handleSelectionChange(data) {
      if (data.length) {
        data.forEach((e) => {
          this.selectedRowIds.push(e.id);
        });
      } else {
        this.selectedRowIds = [];
      }
    },

    // 批量删除
    batchRemove() {
      if (this.selectedRowIds.length === 0) {
        return;
      }
      this.$confirm('确认删除?').then(() => {
        service
          .batchRemoveFaultAnalyHistory(this.selectedRowIds)
          .then((deletedCount) => {
            if (deletedCount > 0) {
              uikit.message('批量删除成功');
              this.loadHistory();
            }
          });
      });
    },

    // 删除
    remove(id) {
      this.$confirm('确认删除?').then(() => {
        service.batchRemoveFaultAnalyHistory([id]).then((deletedCount) => {
          if (deletedCount > 0) {
            uikit.message('删除成功');
            this.loadHistory();
          }
        });
      });
    },
    formatTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm');
    },
  },
};
