<template>
  <div id="tags-view-container" class="tags-view-container">
    <div class="switch-img">
      <img :src="toggleImg" @click="toggleSidebar" alt="" />
    </div>
    <div class="tabs-box">
      <el-tabs v-model="editableTabsValue" closable @tab-remove="removeTab" @tab-click="switchTab">
        <el-tab-pane
          v-for="tag in visitedViews"
          :key="tag.route"
          :label="tag.display"
          :name="tag.menuName"
          :lazy="true"
        />
      </el-tabs>
    </div>
  </div>
</template>

<script>
import sharedActions from '@/shared/actions';

export default {
  props: {
    currentMenuName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editableTabsValue: '',
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    toggleImg() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/images/${this.$store.state.app.sidebarIsCollapse ? 'expand' : 'retract'}.png`);
    },
  },
  mounted() {
    const currentPath = this.$route.path;
    this.editableTabsValue = this.visitedViews.find((item) => item.isActive.includes(currentPath)).menuName;
  },
  methods: {
    menuIsActive(routeName = []) {
      if (this.$route.name.includes('micro')) {
        return routeName.includes(this.$route.path);
      }
      return routeName.includes(this.$route.name);
    },
    goto(menu, params) {
      if (menu.route && menu.route !== '') {
        if (menu.route === 'home') {
          const routeData = this.$router.resolve({ name: 'home' });
          window.open(routeData.href, '_blank');
          return;
        }
        if (menu.route === 'prototypeTurbine') {
          this.createUserFnCount(menu.menuName);
          const routeData = this.$router.resolve({ name: 'prototypeTurbine' });
          window.open(routeData.href, '_blank');
          return;
        }
        if (menu.route === 'farmsControlOverviewNew') {
          this.createUserFnCount(menu.menuName);
          const routeData = this.$router.resolve({ name: 'farmsControlOverviewNew' });
          window.open(routeData.href, '_blank');
          return;
        }
        // 地形分析兼容处理
        if (menu.route === 'mapAnalysis') {
          window.open('/micro-apps/map/map-analysis');
          return;
        }
        // // 能管分析兼容处理
        if (menu.route === 'energyManagement') {
          window.open('/micro-apps/tool/energy-management');
          return;
        }
        // 微应用跳转方式
        if (menu.route.includes('/micro/')) {
          this.$router.push({ path: menu.route, params, query: menu.query });
        } else {
          // 主app内跳转方式
          this.$router.push({ name: menu.route, params, query: menu.query });
        }
      } else {
        // TODO? 改为读取环境变量 --- 改掉
        // eslint-disable-next-line no-lonely-if
        if (menu.menuName === 'yunwei') {
          if (this.localUrl.indexOf('dc.sanywind.net') > -1) {
            window.open('https://ops.sanywind.net');
          } else if (this.localUrl.indexOf('10.162.4.4') > -1) {
            window.open('http://10.162.4.4:21002/#/');
          } else {
            window.open('https://ops.sanywind.net');
          }
        } else if (menu.menuName === 'zhishi') {
          this.createUserFnCount(menu.menuName);
          if (this.localUrl.indexOf('dc.sanywind.net') !== -1) {
            window.open('https://wiki.sanywind.net/portal');
          } else {
            // window.open('http://10.162.4.40:21003/portal');
            window.open('https://wiki.sanywind.net/portal');
          }
        } else {
          window.open(menu.href);
        }
      }
    },
    closeSelectedTag(view) {
      this.$store.dispatch('delVisitedView', view).then((visitedViews) => {
        if (view.route.startsWith('/micro/')) {
          sharedActions.setGlobalState({ app: 'main', compName: view.comName, type: 'minus' });
        } else {
          this.$emit('minusMainInclude', view.comName);
        }
        if (this.menuIsActive(view.isActive)) {
          this.toLastView(visitedViews, view);
        }
      });
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        // 微应用跳转方式
        if (latestView.route.includes('/micro/')) {
          this.$router.push({ path: latestView.route, query: latestView.query });
          this.$store.dispatch('delVisitedView', view);
        } else {
          // 主app内跳转方式
          this.$router.push({ name: latestView.route, query: latestView.query });
          this.$store.dispatch('delVisitedView', view);
        }
      }
    },
    toggleSidebar() {
      this.$store.dispatch('toggleSidebar');
    },
    handleScroll() {
      this.closeMenu();
    },

    // 移出tab页
    removeTab(targetName) {
      if (targetName === this.editableTabsValue) {
        const idx = this.visitedViews.findIndex(((item) => item.menuName === targetName));
        const nextTab = this.visitedViews[idx + 1] || this.visitedViews[idx - 1];
        if (nextTab) {
          this.editableTabsValue = nextTab.menuName;
          this.$emit('updateMenuName', nextTab.menuName);
        }
      }
      const delTab = this.visitedViews.find((item) => item.menuName === targetName);
      this.closeSelectedTag(delTab);
    },

    // 切换tab页
    switchTab() {
      const selTab = this.visitedViews.find((item) => item.menuName === this.editableTabsValue);
      this.goto(selTab);
      this.$emit('updateMenuName', selTab.menuName);
    },
  },
  watch: {
    currentMenuName(newVal) {
      this.editableTabsValue = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-view-container {
  display: flex;
  z-index: 9;
  position: relative;
  height: 38px;
  width: 100%;

  .switch-img {
    overflow: hidden;
    align-self: center;
    width:26px;
    height:26px;
    margin-right: 5px;
    cursor: pointer;

    img {
      height: 100%;
    }
  }
  .tabs-box {
    width: calc(100% - 26px);
    height: 40px;
    overflow: hidden;
  }
  .tags-view-wrapper {
    position: fixed;
    background-color: #F4F6FA;
    padding-bottom: 6px;
    padding-top: 6px;
    ::v-deep(.el-scrollbar__view){
      display: flex;
    }
    .toggle_img{
      width:26px;
      height:26px;
      margin-right:10px;
      cursor: pointer;
    }
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 24px;
      border: 1px solid #e1e4ec;
      border-radius: 4px;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 10px;
      &:first-of-type {
        margin-left: 0px;
      }
      &:last-of-type {
        margin-right: 8px;
      }
      &.active {
        background-color: #3e65ff;
        color: #fff;
        border-color: #3e65ff;
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
::v-deep(.el-tabs__item.is-top.is-closable) {
  height: 30px;
  line-height: 30px;
  padding: 0 8px !important;
  font-size: 12px;
  text-align: center;
  color: #0c0b0b;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e1e4ec;
  margin-top: 5px;
  margin-left: 10px;

  &.is-active {
    color: #fff;
    border-radius: 4px;
    background-color: #3e65ff;
  }
}
::v-deep(.el-tabs__nav-scroll) {
  height: 40px;
  .el-tabs__active-bar {
    bottom: -5px;
    background-color: transparent;
  }
}
::v-deep .el-tabs__nav-wrap.is-scrollable.is-top {
  .el-tabs__nav-scroll {
    height: 40px;
    .el-tabs__active-bar {
      bottom: -5px;
      background-color: transparent;
    }
  }
  .el-tabs__nav-prev,.el-tabs__nav-next {
    margin-top: 5px;
    line-height: 30px;
    background-color: #fff;
    height: 30px;
  }
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      font-size: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
    }
  }
}
</style>
