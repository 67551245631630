import { userService as service } from '@/service';
import roles from '@/store/data/roles';

export const state = {
  pageData: {
    data: [],
    page: 1,
    limit: 20,
    total: 0,
  },
  roles,
};

export const mutations = {

  SET_USER_PAGEDATA(state, users) {
    state.pageData = users;
  },
};

export const actions = {

  searchUserList({ commit, dispatch, getters }, postData = {}) {
    return service.searchUsers(postData).then((data) => {
      commit('SET_USER_PAGEDATA', data);
      return data;
    });
  },

  createUser({ commit, dispatch, getters }, newUser = {}) {
    return service.createUser(newUser).then((data) => {
      return data;
    });
  },

  updateUser({ commit, dispatch, getters }, {
    id, nickname, dept, roleObj,
  }) {
    return service.updateUser(id, nickname, dept, roleObj).then((data) => {
      return data;
    });
  },

  changePassword({ commit, dispatch, getters }, { userId, newPwd }) {
    return service.setUserPassword(userId, newPwd).then((data) => {
      return data;
    });
  },

  deleteUser({ commit, dispatch, getters }, { id }) {
    return service.deleteUser(id).then((data) => {
      return data;
    });
  },

};

export default {
  state,
  mutations,
  actions,
  // getters,
};
