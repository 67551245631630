<template>
  <div class="empty" />
</template>
<script>
import routes from '@/router/routes';

export default {
  data() {
    return {};
  },
  mounted() {
    if (decodeURIComponent(this.$route.path) !== this.$route.query.originalPath) {
      const defaultRoute = routes.find((r) => r.path === '/') || { redirect: '/login' };
      window.location.href = `${defaultRoute.redirect}?originalPath=${encodeURIComponent(defaultRoute.redirect)}`;
    }
  },
};
</script>
