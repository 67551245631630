import alarmReport from './alarmReport';
import app from './app';
import auth from './auth';
import user from './user';
import farm from './farm';
import cms from './cms';
import model from './model';
import report from './report';
import turbineOps from './turbineOps';
import cvtData from './cvtData';
import tagsView from './tagsView';

const storeData = {
  modules: {
    turbineOps,
    app,
    auth,
    user,
    farm,
    cms,
    model,
    report,
    alarmReport,
    cvtData,
    tagsView,
  },
};

export default storeData.modules;
