import { modelService as service } from '@/service';

export const state = {
  allModelGroup: [],
};

export const mutations = {
  SET_ALL_MODEL_GROUP(state, data) {
    state.allModelGroup = data;
  },
};
export const getters = {
  allModels(state) {
    return (state.allModelGroup || []).reduce((acc, cur) => {
      return acc.concat(cur.models);
    }, []);
  },
};

export const actions = {
  async getAllModelGroup(context, data = {}) {
    const allModelGroups = await service.fetchModelGroups(data);
    context.commit('SET_ALL_MODEL_GROUP', allModelGroups);
    return { data: allModelGroups };
  },
};

const alarmReport = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
export default alarmReport;
