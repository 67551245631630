<script>
import Layout from '@/views/layouts/main.vue';
import { cmsService as service, modelService } from '@/service';
import _ from 'lodash';
import ImgViewer from '@/components/ImgViewer';

import moment from 'moment';

export default {
  name: 'TurbineModelResult',
  page: {
    title: '风场模型预警',
  },
  components: {
    Layout,
    ImgViewer,
  },
  data: () => {
    return {
      currentDate: moment(new Date()).format('YYYY-MM-DD'),
      headerData: [],
      tableData: {},
      imageTitleObj: {},

    };
  },

  computed: {

    farmDetail() {
      const farms = this.$store.state.farm.list.filter((ent) => ent.pinyinCode === this.$route.params.pinyinCode);
      return farms.length > 0 ? farms[0] : null;
    },

    // 该风场下的所有风机
    farmTurbineList() {
      const farms = this.$store.state.farm.list.filter((f) => f.pinyinCode === this.$route.params.pinyinCode);
      if (farms.length > 0) {
        return farms[0].turbines;
      }
      return [];
    },
  },
  watch: {
    tag(newValue, oldValue) {
      modelService.fetchModelGroups({ tag: newValue }).then((result) => {
        this.modelGroups = result;
      });
    },
  },
  mounted() {
    const breadcrumbs = [
      {
        display: '风场模型预警',
        canGoBack: true,
      }, {
        display: this.farmDetail.farmName,
        canGoBack: false,
      }];
    this.$store.dispatch('setBreadcrumbs', { breadcrumbs });
    this.getTableHeader();
    this.fetchTurbineModelResult();
  },
  // fetchTurbineModelLastTwoResult
  methods: {
    closeFlatPickr(dateObject, dateString) {
      this.currentDate = dateString;
      this.fetchTurbineModelResult();
    },
    changeDate(day) {
      const fpInstance = this.$refs.datePicker.fp;
      const newDate = moment(this.currentDate).add(day, 'days').format('YYYY-MM-DD');
      this.currentDate = newDate;
      fpInstance.setDate(newDate);
      this.fetchTurbineModelResult();
    },
    getTableHeader() {
      service.getModelHeader().then((res) => {
        this.headerData = res;
      });
    },
    fetchTurbineModelResult() {
      const option = {
        pinyinCode: this.farmDetail.pinyinCode,
      };
      service.fetchCmsTurbineModelResult(option).then((result) => {
        this.tableData = result;
      });
    },
    displaySatus(turbineName, part) {
      return _.get(this.tableData, `${turbineName}.${part.partAscription}.status`);
    },
    show(turbineName, part) {
      this.imageTitleObj = {
        farmName: this.farmDetail.farmName,
        turbineName,
        displayName: part.partAscription,
        status: _.get(this.tableData, `${turbineName}.${part.partAscription}.status`),
        description: _.get(this.tableData, `${turbineName}.${part.partAscription}.result`),
      };
      const uploadFilePath = _.get(this.tableData, `${turbineName}.${part.partAscription}.uploadFilePath`);
      if (!uploadFilePath) {
        return;
      }
      this.$refs.viewer.show([{ src: uploadFilePath }], 0);
    },

  },

};
</script>

<template>
  <Layout>
    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="table-responsive">
        <div class="table-sticky-wrapper">
          <table class="table">
            <thead>
              <tr>
                <th rowspan="2">
                  <span>风机</span>
                </th>
                <th v-for="part in headerData" :key="part.partAscription">
                  <span>{{ part.partAscription }}</span>
                  <div>{{ part.modelExecuteTimeLast|dateFormat('MM-DD') }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="farmTurbineList.length > 0">
              <tr v-for="turbine in farmTurbineList" :key="turbine.turbineName">
                <td>{{ turbine.turbineName }}({{ turbine.ownerTurbineName }})</td>
                <template v-for="part in headerData">
                  <td :key="part.modelExecuteTimeLast" @click="show(turbine.turbineName, part)">
                    <span v-tooltip.bottom-start="'查看结果图片'" class="cursor-pointer health" :class="{ warning: displaySatus(turbine.turbineName, part) === '告警', error: displaySatus(turbine.turbineName, part) === '故障' }">
                      {{ displaySatus(turbine.turbineName, part) }}
                    </span>
                  </td>
                </template>
              </tr>
            </tbody>

            <tbody v-else>
              <el-empty description="暂无数据" />
            </tbody>
          </table>
          <ImgViewer ref="viewer" :alt-obj="imageTitleObj" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
@import './model.scss' ;

.health {
  color: #8dbf42!important;
}
.warning {
  border-color: red;
}
.error {
  color:#9d2124!important;
}

</style>
