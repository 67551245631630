<script>
import { showPopup } from '@sany/captcha-verify/showPopup';
import { EyeOffIcon, EyeIcon } from 'vue-feather-icons';
import { mapGetters } from 'vuex';
import { uikit } from '@/utils';

export default {
  name: 'Login',
  page: {
    title: ' 登录',
  },
  components: {
    EyeOffIcon,
    EyeIcon,
  },
  data() {
    return {
      username: '',
      password: '',
      showPwd: false,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
  methods: {
    togglePwd() {
      this.showPwd = !this.showPwd;
    },
    verifyCode() {
      if (!this.app.isInternetAvaliabale) {
        const appId = window.SANY_SETTINGS.VUE_APP_CAPTCHA_VERIFY_APP_ID;
        showPopup(appId).then((res) => {
          const { ret, ticket, randstr } = res;
          this.login({ ticket, randstr });
        }).catch((err) => {
          console.log(err);
          this.$message.error('验证失败');
        });
      } else {
        this.login();
      }
    },
    // eslint-disable-next-line consistent-return
    login(verifyData = {}) {
      const formData = { username: this.username, password: this.password, verifyData };
      if (!this.username || !this.password) {
        uikit.message('用户名密码错误');
        return false;
      }
      this.$store.dispatch('login', formData).then(async (data) => {
        if (data.message === 'ok') {
          uikit.success('登录成功');
          await this.$store.dispatch('fetchProfile');
          setTimeout(() => {
            const { redirect } = this.$route.query;
            // SANYSSO
            // 如果是 SSO 登录，则需要刷新页面已实现登录成功重定向
            if (redirect && redirect.split('?')[0] === '/api/sso') {
              window.location.href = redirect;
            } else {
              const { profile } = this.$store.state.auth;
              if (!profile.dept && profile.role === 'guest') {
                this.$router.push('/register');
              } else if (redirect) {
                window.location.href = redirect;
              } else {
                window.location.href = '/';
              }
            }
          }, 1000);
        } else {
          uikit.error(`登录失败，${data.message}`);
        }
      });
    },
  },
};
</script>

<template>
  <div class="login-container">
    <div class="login-logo" />
    <div class="left-panel" />
    <div class="right-panel">
      <div class="form-content">
        <h1 class="title">
          试验检测数据中心
        </h1>
        <div class="text-left">
          <div class="form">
            <!-- 用户名 -->
            <div id="username-field" class="field-wrapper">
              <el-input v-model="username" class="input-login" placeholder="请输入用户名" prefix-icon="el-icon-user" />
            </div>
            <!-- 密码 -->
            <div id="password-field" class="field-wrapper">
              <el-input
                v-model="password"
                placeholder="请输入密码"
                prefix-icon="el-icon-lock"
                :type="showPwd ? 'text' : 'password'" />
            </div>
            <!-- 是否显示密码 -->
            <div class="wrap-show">
              <div class="toggle-pass" @click="togglePwd">
                <p style="margin-right: 10px">
                  显示密码
                </p>
                <EyeOffIcon v-if="!showPwd" />
                <EyeIcon v-else />
                <!-- <el-switch v-model="showPwd" active-color="#1b55e2" class="switch-show"></el-switch> -->
              </div>
            </div>
            <el-button type="primary" class="btn-primary" @click="verifyCode">
              登录
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-container {
  margin: 0;
  width: 100%;
  height: 100vh;
  background-image: url('@/assets/images/bg-lab.jpg');
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-logo {
  width: 150px;
  height: 40px;
  background: url("@/assets/images/login-logo.jpg") no-repeat;
  position: absolute;
  top: 40px;
  left: 50px;
}

.left-panel {
  flex: 5;
}

.right-panel {
  flex: 1;
  flex-shrink: 0;
  flex-basis: 400px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

@media screen and (min-width: 1921px) {
  .form-content {
    scale: 1.2;
  }
}

@media screen and (min-width: 2561px) {
  .form-content {
    scale: 1.5;
  }
}

@media screen and (min-width: 2881px) {
  .form-content {
    scale: 2;
  }
}

.form-content {
  flex: 0 0 400px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  padding: 50px;
  padding-bottom: 30px;

  .title {
    text-align: center;
    font-size: 22px;
  }

  ::v-deep(.el-input__inner) {
    height: 56px;
    background: transparent;
    line-height: 44px;
    font-weight: 600;
    color: #242426;
    /* border-color: #8faefe; */
    border-radius: 50px;
    text-indent: 10px;
    padding-left: 56px;
  }

  ::v-deep(.el-input__prefix) {
    left: 20px;
  }

  ::v-deep(.el-input__icon) {
    font-size: 22px;
    margin-left: 2px;
    color: #1b55e2;
  }

  ::v-deep(.el-button) {
    padding: 15px 15px;
    height: 56px;
    border-radius: 50px;
  }

  .toggle-pass {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      font-weight: 600;
      color: #3b3f5c;
      margin-bottom: 0;
    }

    .switch-show {
      margin-left: 10px;
    }

    svg {
      color: #1b55e2;
      width: 20px;
      height: 20px;
    }
  }

  .wrap-show {
    margin: 25px 0;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
  }

  .btn-primary {
    width: 100%;
    color: #fff;
    background-color: #1b55e2;
    border-color: #1b55e2;
  }

  .icon {
    font-size: 22px;
  }

  .login-feishu-wrapper {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-feishu {
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-16px);
  }

  .feishu-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;

    .feishu {
      background: url("@/assets/images/feishu.png") no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      margin: auto;
      margin-top: 4px;
    }
  }
}

.field-wrapper {
  margin-top: 28px;

  &:first-of-type {
    margin-top: 40px;
  }
}</style>
