<script>
export default {
  name: 'Layout',
  data: () => {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.state.app.isLoading;
    },
  },
};
</script>

<template>
  <div v-loading="isLoading" class="layout" element-loading-fullscreen="true">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.layout {
  width: 100%;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #727272;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.el-loading-mask{
  z-index: auto!important;
}
</style>
