<template>
  <!--  BEGIN NAVBAR  -->
  <div class="header-container fixed-top">
    <header class="navbar">
      <div class="logo">
        <img src="@/assets/images/logo-wsc.png" alt="" />
        <span class="title">智慧风场云端数据中心</span>
      </div>
      <!-- 占位 -->
      <span class="collect" />
      <el-tooltip effect="dark" content="功能指导手册" placement="bottom">
        <i class="iconfont icon-manual" style="color:#182033;cursor: pointer;margin-right:20px;" @click="navTo('https://rs2flu7c17.work.sany.com.cn/wiki/wikk5sQKT9E6oTn4hnOHmFi8N3d')" />
      </el-tooltip>
      <el-tooltip effect="dark" content="问题反馈" placement="bottom">
        <i class="iconfont icon-feedback" style="color:#182033;cursor: pointer;margin-right:30px;" @click="navTo('https://rs2flu7c17.work.sany.com.cn/share/base/form/shrk5XnPN33pUEcHIrGM1AuWSDb')" />
      </el-tooltip>
      <div class="user-wrap">
        <ul class="navbar-info">
          <el-dropdown>
            <a
              href="javascript:void(0);"
              class="user"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ profile.nickname }}
              <i class="el-icon-caret-bottom caret" />
            </a>
            <el-dropdown-menu slot="dropdown" class="user-profile-dropdown">
              <UserFile />
            </el-dropdown-menu>
          </el-dropdown>
        </ul>
      </div>
    </header>
  </div>
  <!--  END NAVBAR  -->
</template>

<script>
import sharedActions from '@/shared/actions';
import { menusList } from '@/components/menus';
import _ from 'lodash';
import UserFile from './user-file/index.vue';

export default {
  components: {
    UserFile,
  },
  data() {
    return {
      menusList,
    };
  },
  computed: {
    profile() {
      return this.$store.state.auth.profile;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const homePage = this.getNode(menusList, 'faultAnalySearch', 'menuName');
      if (homePage) {
        this.$store.dispatch('addVisitedView', {
          ...homePage,
          isFirst: true,
        });
        sharedActions.setGlobalState({
          app: 'main',
          compName: homePage.comName,
          type: 'add',
        });
      }
    },
    getNode(tree, menuName, key) {
      // eslint-disable-next-line no-restricted-syntax
      for (const node of tree) {
        if (node[key] === menuName) {
          return node;
        }
        if (node.child) {
          const result = this.getNode(node.child, menuName, key);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    navTo(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
  height: 40px;
  width: 100%;
  .logo {
    display: flex;
    align-items: center;
    padding-right: 60px;
    img{
      width: 26px;
      height: 26px;
    }
    .title {
      margin-left: 14px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #182033;
      line-height: 40px;
    }
  }
  .collect{
    flex: 1;
  }
  .user-wrap{
    display: flex;
    align-items: center;
  }
}
.user {
  color: #515365;
  display: flex;
  justify-content: center;
  align-items: center;
  .caret{
    padding: 0 10px 0 4px;
  }
}
</style>
