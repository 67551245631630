import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  getters,
  // todo
  strict: process.env.NODE_ENV !== 'production',
});

export default store;

// todo
// dispatchActionForAllModules('init')
