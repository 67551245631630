export const state = {
  dataTypes: [
    {
      id: 1,
      name: 'ALL',
      value: ['hopewind', 'sungrow'],
      label: '所有变流器数据',
      children: [
        {
          id: 2,
          name: 'hopewind',
          label: '禾望变流器',
          value: ['event', 'interfceside', 'gridside', 'fanside', 'record'],
          children: [{
            id: 3,
            name: 'event',
            parentNode: 'hopewind',
            label: '事件记录',
            isChild: true,
          }, {
            id: 4,
            name: 'interfceside',
            parentNode: 'hopewind',
            label: '接口故障录波',
            isChild: true,
          }, {
            id: 5,
            name: 'gridside',
            parentNode: 'hopewind',
            label: '网侧故障录波',
            isChild: true,
          }, {
            id: 6,
            name: 'fanside',
            parentNode: 'hopewind',
            label: '机侧故障录波',
            isChild: true,
          }, {
            id: 7,
            name: 'record',
            parentNode: 'hopewind',
            label: '触发录波数据',
            isChild: true,
          }],
        }, {
          id: 8,
          name: 'sungrow',
          label: '阳光变流器',
          value: ['event', 'fault', 'fanside', 'gridside'],
          children: [{
            id: 9,
            name: 'event',
            parentNode: 'sungrow',
            label: '事件记录',
            isChild: true,
          }, {
            id: 10,
            name: 'fault',
            parentNode: 'sungrow',
            label: '故障记录',
            isChild: true,
          }, {
            id: 11,
            name: 'fanside',
            parentNode: 'sungrow',
            label: '机侧故障录波',
            isChild: true,
          }, {
            id: 12,
            name: 'gridside',
            parentNode: 'sungrow',
            label: '网侧故障录波',
            isChild: true,
          }],
        },
      ],
    },

  ],
  fileTypeList: [
    {
      label: '事件数据',
      value: 'cvt_event',
    },
    {
      label: '接口故障',
      value: 'cvt_interfceside',
    },
    {
      label: '历史故障',
      value: 'cvt_fault',
    },
    {
      label: '网侧录波',
      value: 'cvt_gridside',
    },
    {
      label: '机侧录波',
      value: 'cvt_fanside',
    },
    {
      label: '触发录波',
      value: 'cvt_record',
    },
  ],
};
export default {
  state,
};
