const serviceCompany = {
  name: '服务公司',
  value: 'service_company',
  level: 10,
};

const researchInstitute = {
  name: '研究院内部',
  value: 'research_institute',
  level: 20,
};
const modelDeveloper = {
  name: '模型开发者',
  value: 'model_developer',
  level: 30,
};
const internalOperation = {
  name: '内部运维',
  value: 'internal_operation',
  level: 40,
};
const admin = {
  name: '管理员',
  value: 'admin',
  level: 50,
};
// model_developer
// internal_operation
const roleList = [admin, internalOperation, modelDeveloper, researchInstitute, serviceCompany];
const roleMap = {
};
roleList.forEach((role) => {
  roleMap[role.value] = role;
});

module.exports = {
  list: roleList,
  map: roleMap,
};
