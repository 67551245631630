<template>
  <div v-show="showBtn" class="img-viewer-box">
    <viewer
      v-if="images && images.length"
      ref="viewer"
      :images="images"
      :options="options"
      class="viewer"
      @inited="inited">
      <template slot-scope="scope">
        <!-- <img v-for="src in scope.images" :src="src" :key="src"> -->
        <el-button type="warning" size="mini" @click="handlePreClick">
          查看上一台风机
        </el-button>
        <p style="height: 10px" />
        <el-button type="success" size="mini" @click="handleNextClick">
          查看下一台风机
        </el-button>

        <img
          v-for="img in scope.images"
          :key="img.src"
          :data-source="`/api/v3/cos/exchange?key=${encodeURIComponent(img.src)}`"
          :src="`/api/v3/cos/exchange?key=${encodeURIComponent(img.src)}`"
          :alt="`${altObj.farmName}-${altObj.turbineName}-${altObj.displayName}-${altObj.status || '正常'}-${altObj.description}`"
          class="image">
        <!-- {{ scope.options }} -->
      </template>
    </viewer>
  </div>
</template>

<script>
export default {
  props: ['showBtn', 'altObj'],
  data() {
    return {
      showImageIndex: -1, // 当前正在显示的image 的index
      options: {
        url: 'data-source',
        view: (e) => {
          this.showImageIndex = e.detail.index;
        },
        hide: () => {
          this.$parent.showBtn = false;
        },
      },
      index: 0,
      images: [],
    };
  },
  watch: {

  },
  mounted() {
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
      this.$viewer.view(this.index);
    },
    view(index) {
      this.index = index;
      this.$viewer.view(this.index);
    },
    show(images, index = 0) {
      if (this.images === images) {
        this.view(index);
        return;
      }
      this.images = images;
      this.index = index;
    },
    handlePreClick() {
      this.$parent.getPreTurbineImages(this.altObj.turbineName, this.showImageIndex);
    },
    handleNextClick() {
      // this.images = [{ src: 'model_result/fig/vib_rose/Ralls/001_2x-yaw-vib_2021-01-26_2021-03-27_3c382dfe-8eca-11eb-977d-0242ac110004.png' }]
      this.$parent.getNextTurbineImages(this.altObj.turbineName, this.showImageIndex);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.img-viewer-box {
  position: absolute;
  z-index: 10000;
  right: 100px;
  top: 30px;
}
.image {
  display: none;
}
</style>

<style>
.viewer-loading > img {
  display: none; /*hide big images when it is loading */
}
.viewer-canvas > img {
  background: #fff !important;
}
.viewer-list img {
  background: #fff;
}
.viewer-footer .viewer-title {
  color: #fff;
  font-size: 14px;
  opacity: 1;
}
</style>
